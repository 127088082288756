import { useEffect, useState } from 'react';
import { UnityPlayer } from './UnityPlayer';
import { useParams } from 'react-router-dom';

const UnityNavigator = () => {
    const { mode, item } = useParams();

    let model: string | undefined = undefined;
    let zone: string | undefined = undefined;

    if (mode === "model") {
        model = item;
    }
    else {
        zone = item;
    }

    // Print the model and zone
    console.log("UnityNavigator: mode=" + mode + ", item=" + item);

    return (
        <UnityPlayer
            mode={mode || "zone"}
            model={model}
            zone={zone}
            reload={false}
            setReload={() => { }}
        />
    );
};

export { UnityNavigator };