import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'
import CookieBanner from '../CookieBanner/CookieBanner';
import './LauncherPage.css';

interface LauncherPageProps {

}

const linuxLink = "https://cache.nejlika.org/patcher_linux.AppImage";
const windowsLink = "https://cache.nejlika.org/patcher_windows.exe";
const macLink = "";

export const LauncherPage: React.FC<LauncherPageProps> = () => {

    return (
        <>
        <div>
            {/* Content Section */}
            <div id="content" className="content-section py-5">
                <Container>
                    <Row>
                        <h3 className="text-center mb-4">Step 1. Download the Launcher</h3>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/Tux.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Linux</Card.Title>
                                <Card.Text className="text-class">
                                    Download the Nejlika Launcher for Linux. Requires AppImageLauncher, which is included in most modern Linux distributions. Single-click application.
                                </Card.Text>
                                <Card.Link href={linuxLink}>Download</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/Windows.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Windows</Card.Title>
                                <Card.Text className="text-class">
                                    Download the Nejlika Launcher for Windows. Requires installation.
                                </Card.Text>
                                <Card.Link href={windowsLink}>Download</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/MacOS.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Mac OS</Card.Title>
                                <Card.Text className="text-class">
                                    The Mac version is currently in development. Sorry!
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <h3 className="text-center mb-4">Step 2. Prepare Client</h3>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/link.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Verify</Card.Title>
                                <Card.Text className="text-class">
                                    <strong>Nejlika does not distribute the game client.</strong> You must provide your own copy of LEGO® Universe. Refer to the <a href="https://github.com/DarkflameUniverse/DarkflameServer?tab=readme-ov-file#verifying-your-client-files">Darkflame Universe README</a> for instructions on how to verify your client.
                                    <br /><br/>
                                    Nejlika currently requires an unpacked client.
                                    <br /><br/>
                                    <strong>Do not download the client from websites that advertise it!</strong> There are malicious actors that distribute the client and their integrity cannot be guaranteed, and their activites should not be encouraged.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/zip.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Extract</Card.Title>
                                <Card.Text className="text-class">
                                    Extract the client to a folder of your choice. The folder should in part contain the following files and folders:
                                    <ul>
                                        <li>legouniverse.exe</li>
                                        <li>boot.cfg</li>
                                        <li>cop.dll</li>
                                        <li>res/</li>
                                        <li>locale/</li>
                                    </ul>
                                    <br/><br/>
                                    Remember where you extract the client, the Nejlika Launcher will ask for the location.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/wine.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Run it once</Card.Title>
                                <Card.Text className="text-class">
                                    Run the client once to generate the necessary files. You may close it after getting to the login screen.
                                    <br/><br/>
                                    The client requires a compatibility layer to run on Linux and MacOS. This is usually Wine. Download Wine through your package manager.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <h3 className="text-center mb-4">Step 3. Configure Launcher</h3>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/host.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Check Hosts</Card.Title>
                                <Card.Text className="text-class">
                                    Click the "Configure" button in the Nejlika Launcher. Make sure the following values are set:
                                    <ul>
                                        <li>Cache Server: cache.nejlika.org</li>
                                        <li>Server Host: nejlika.org</li>
                                    </ul>
                                    <br/><br/>
                                    If you are playing on Linux or MacOS, set the Start Command to "wine" or other compatibility layer (like Proton).
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/gear.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Configure Client</Card.Title>
                                <Card.Text className="text-class">
                                    Click the "Browse" button below the "Client Directory" field. Select the folder where you extracted the client. It should be the folder containing legouniverse.exe.
                                    <br/><br/>
                                    Click the "Fetch" button below the "Game Config" field. This will attempt to find the lwo.xml file on your machine. If it did not find it, put in the correct path manually.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/project.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Select Project</Card.Title>
                                <Card.Text className="text-class">
                                    Select the project you want to play on from the dropdown menu under the "Project" field.
                                    <br/><br/>
                                    The project will have to have been deployed once and be online for you to play on it.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <h3 className="text-center mb-4">Step 4. Play</h3>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/login.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Login</Card.Title>
                                <Card.Text className="text-class">
                                    Exit the configuration window and click the "Login" button. The launcher will then open up a browser window for you to authenticate with Discord.
                                    <br/><br/>
                                    After authentication, the download will start automatically.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/download.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Download</Card.Title>
                                <Card.Text className="text-class">
                                    Let the download finish, it might take a while.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={`/images/play.svg`} className='os-img mx-auto d-block' />
                                <Card.Body>
                                <Card.Title>Play</Card.Title>
                                <Card.Text className="text-class">
                                    When the download is finished, click the "Play" button. The game will start up and you should be immediately be directed to the character creation screen.
                                    <br/><br/>
                                    If you are not directed to the character creation screen, make sure the project's server has been deployed and is online. A re-deployment might fix the issue.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Footer with links to policies */}
            <footer className="footer bg-dark text-white text-center py-3">
                <Container>
                    <span>
                        <a href="/">Home</a>
                    </span>
                    <span> | </span>
                    <span>
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy and Cookie Policy</a>
                    </span>
                </Container>
            </footer>
        </div>
        </>
    );
}