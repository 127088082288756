import './components.css';

interface GenericTextProps {
    title: string;
}

export const GenericText: React.FC<GenericTextProps> = ({ title }) => {
    return (  
        <p className='w-100 vs-bg wheat-text'>{title}</p>
    );
};