import React, { useState, useEffect, useCallback } from "react";
import { Table } from "react-bootstrap";
import ReactCodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { sql, SQLDialect, SQLite } from "@codemirror/lang-sql";
import { LanguageSupport } from "@codemirror/language";
import { basicSetup } from "codemirror";
import { autocompletion, Completion } from "@codemirror/autocomplete";
import { modService } from "../ModService";
import { Severity, LogMessage } from "../Logger";
import Cookies from "js-cookie";

import "./DatabasePanel.css";

interface DatabasePanelProps {}

export const DatabasePanel: React.FC<DatabasePanelProps> = () => {
    const [sqlInput, setSqlInput] = useState<string>("");
    const [sqlOutput, setSqlOutput] = useState<any>(null);
    const [useAppliedContext, setUseAppliedContext] = useState<boolean>(false);
    const [tableSchema, setTableSchema] = useState<{ [table: string]: { name: string, type: string }[] }>({});
    const [dialect, setDialect] = useState<SQLDialect>(SQLite);
    const [language, setLanguage] = useState<LanguageSupport>(sql({ dialect: dialect }));
    const [disableReapply, setDisableReapply] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<{ column: string | null; order: "asc" | "desc" }>({
        column: null,
        order: "asc",
    });
    const [offset, setOffset] = useState<number>(0);

    const sortData = useCallback(() => {
        if (sqlOutput && sortConfig.column) {
            const sortedData = [...sqlOutput].sort((a, b) => {
                const aValue = a[sortConfig.column!];
                const bValue = b[sortConfig.column!];
                if (aValue == null && bValue == null) return 0;
                if (aValue == null) return sortConfig.order === "asc" ? 1 : -1;
                if (bValue == null) return sortConfig.order === "asc" ? -1 : 1;
                if (aValue < bValue) return sortConfig.order === "asc" ? -1 : 1;
                if (aValue > bValue) return sortConfig.order === "asc" ? 1 : -1;
                return 0;
            });
            setSqlOutput(sortedData);
        }
    }, [sqlOutput, sortConfig]);

    useEffect(() => {
        sortData();
    }, [sortConfig, sortData]);

    const handleSort = useCallback((column: string) => {
        setSortConfig((prevConfig) => ({
            column,
            order: prevConfig.column === column && prevConfig.order === "asc" ? "desc" : "asc",
        }));
    }, []);
    
    // Generate SQL auto-completions based on schema
    const schemaCompletions = useCallback((): Completion[] => {
        const completions: Completion[] = [];
        Object.keys(tableSchema).forEach((table) => {
            completions.push({ label: table, type: "table" });
            tableSchema[table].forEach((column) => {
                completions.push({ label: column.name, type: "column" });
            });
        });
        console.log(completions);
        return completions;
    }, [tableSchema]);

    // Fetch schema on component mount
    useEffect(() => {
        // SQLite
        modService
            .sqlSchema()
            .then((schema) => {
                setTableSchema(schema);
            })
            .catch((err) => {
                LogMessage(err, Severity.ERROR);
            });
    }, []);    

    useEffect(() => {
        setLanguage(
            sql({
                dialect: dialect,
                schema: schemaCompletions(),
            })
        );
    }, [dialect, schemaCompletions, tableSchema]);

    const executeSql = useCallback(() => {
        modService
            .sql(sqlInput, useAppliedContext)
            .then((result) => {
                if (typeof result === 'string') {
                    LogMessage(result, Severity.ERROR);
                    return;
                }

                setSqlOutput(result);
            })
            .catch((err) => {
                LogMessage(err, Severity.ERROR);
            });
    }, [sqlInput, useAppliedContext]);

    const reapplyContext = useCallback(() => {
        setDisableReapply(true);
        modService
            .reapplyProject(Cookies.get("project") ?? "default")
            .then(() => {
                LogMessage("Context re-applied", Severity.INFO);
                setDisableReapply(false);

                executeSql();
            })
            .catch((err) => {
                LogMessage(err, Severity.ERROR);
                setDisableReapply(false);
            });
    }, [executeSql]);

    return (
        <div className="database-panel">
            <div className="panel-section vs-bg">
                <div className="sql-panel-header vs-bg">
                    <button className="btn btn-primary" onClick={executeSql}>
                        Execute
                    </button>
                    <div className="sql-panel-header-divider"></div>
                    <button
                        className={`btn ${useAppliedContext ? "btn-success" : "btn-secondary"}`}
                        onClick={() => {
                            setUseAppliedContext(!useAppliedContext);
                        }}
                    >
                        {useAppliedContext ? "Viewing changes" : "Not viewing changes"}
                    </button>
                    {useAppliedContext && (
                        <>
                        <div className="sql-panel-header-divider"></div>
                        <button
                            className="btn btn-primary"
                            onClick={reapplyContext}
                            disabled={disableReapply}
                        >
                            {disableReapply ? "Reloading changes" : "Reload changes"}
                        </button>
                        </>
                    )}
                </div>
                <div className="panel-body">
                    <div className="sql-editor">
                        <ReactCodeMirror
                            value={sqlInput}
                            height="100%"
                            theme={vscodeDark}
                            extensions={[
                                basicSetup,
                                language,
                            ]}
                            onChange={(value) => setSqlInput(value)}
                        />
                    </div>
                    <div className="sql-results">
                        {sqlOutput && sqlOutput.length === 0 &&
                        <p className="white-text p-3">
                            No results
                        </p>
                        }
                        {sqlOutput && sqlOutput.length > 0 && (
                            <table className="vs-bg sql-results-table">
                                <thead>
                                    {Object.keys(sqlOutput[0]).map((key, i) => (
                                        <th key={i} className="sql-results-th white-text">
                                            {key}
                                            <span
                                                onClick={() => handleSort(key)}
                                                className="sort-button"
                                            >
                                                {sortConfig.column === key ? (
                                                    sortConfig.order === "asc" ? (
                                                        <span>⬆</span> // Up arrow
                                                    ) : (
                                                        <span>⬇</span> // Down arrow
                                                    )
                                                ) : (
                                                    <span>⬍</span> // Neutral
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </thead>
                                <tbody>
                                    {sqlOutput.map((row: any, i: number) => (
                                        <tr key={i} className={`${(i % 2 == 0 ? 'sql-results-row' : 'sql-results-row-odd')}`}>
                                            {Object.keys(row).map((key, j) => (
                                                <td className="sql-results-td white-text" key={j}>{row[key]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
