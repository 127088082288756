import { useCallback, useState } from 'react';
import { NodeProps } from 'reactflow';
import { SimplePropsEntry } from '../SimplePropEntry';
import './behavior.css';
import { Row, Col } from 'react-bootstrap';
import { ObjectCard } from '../ObjectCard';

interface Props {
    value: any;
    setValue: (value: any) => void;
    name: string;
    title: string;
    objectType: string;
    parameters: boolean;
}

export const BehaviorObjectPropWrapper: React.FC<Props> = ({ value, setValue, name, title, objectType, parameters }) => {
    const [ivalue, internalSetValue] = useState<string>((parameters ? value.values["parameters"][name] : value.values[name]) || "lego-universe:0");
    const [showObjectSelect, setShowObjectSelect] = useState<boolean>(false);

    const setiValue = useCallback((val: any) => {
        internalSetValue(val);
        if (parameters) {
            value.values["parameters"][name] = val;
        } else {
            value.values[name] = val;
        }
        setValue(value);
    }, [value]);

    return (
        <div className='node-prop'>
            <Row className='w-100'>
                <Col md={4} className='left-text'>
                    <span className='wheat-text'>{title}:</span>
                </Col>
                <Col md={8} className='left-text no-overflow'>
                    {
                        <span
                            className='wheat-text vs-bg locale-input w-100'
                            lang='en'
                            onClick={() => setShowObjectSelect(true)}
                        >
                            {ivalue}
                        </span>
                    }
                </Col>
            </Row>
            {showObjectSelect && <ObjectCard
                name={name}
                type={objectType}
                value={ivalue}
                setValue={(newObjectValue) => {
                    setiValue(newObjectValue);
                }}
                onClose={() => setShowObjectSelect(false)}
            />
            }
        </div>
    );
}
