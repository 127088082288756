import React from 'react';
import logo from './logo.svg';
import './App.css';
import { modService } from './components/ModService';
import Cookies from 'js-cookie';

function LoadHome() {
    const [id, setId] = React.useState<number>(0);

    const onLoad = async () => {
        const pack = Cookies.get('package');

        modService.loadSkill(id, pack + ":skill:" + id).then((data) => {
            console.log(data);

            modService.createMod(data).then((result) => {
                //window.location.href = `/?uid=${result.uid}`;
            });
        });
    };

    return (
        <div className='row' id='main'>
            <div className='col-12 vs-bg'>
                <div className='center-text'>
                    <h1 className='wheat-text'>Load</h1>
                    <input type='number' className='wheat-text' value={id} onChange={(e) => setId(parseInt(e.target.value))} />
                    <button className='btn btn-primary' onClick={onLoad}>Load</button>
                </div>          
            </div>
        </div>
    );
}

export default LoadHome;
