import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './components.css';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions, TextField } from '@mui/material';
import { definitionKeys } from '../resources/definitions';

interface GenericInputProps {
    title?: string;
    type: 'text' | 'textarea' | 'number' | 'boolean' | 'integer' | 'reference';
    value: any;
    titleClassNames?: string;
    setValue: (value: any) => void;
    readonly?: boolean;
}

export const GenericInput: React.FC<GenericInputProps> = ({ title, type, value, titleClassNames, setValue, readonly }) => {
    const titleClassNamesFinal = titleClassNames ? titleClassNames : 'center-text';
    const isDisabled = readonly ? true : false;

    if (type === 'reference') {
        const OPTIONS_LIMIT = 10;
        const defaultFilterOptions = createFilterOptions();

        const filterOptions = (options: any, state: any) => {
            return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
        };

        return (
            // Select options that begin with the value
            <Autocomplete
                freeSolo={true}
                value={value}
                options={definitionKeys}
                filterOptions={filterOptions}
                renderInput={(params) => <TextField {...params} label="Reference"
                    sx={{ input: { color: 'wheat' }, '& .MuiInputLabel-root': { color: 'wheat' } }}
                />}
                onInputChange={(e, newInputValue) => { setValue(newInputValue); }}
                disabled={isDisabled}
            />
        )
    }

    return (
        <Row>
            {title && (
            <Col md={3} className={titleClassNamesFinal}>
                <p className='wheat-text'>{title}</p>
            </Col>
            )}
            <Col md={title ? 9 : 12}>
                {type === 'textarea' &&
                    <textarea className={"wheat-text vs-bg w-100 generic-input"}
                        value={value} onChange={(e) => { setValue(e.target.value); }}
                        disabled={isDisabled}
                    />
                }
                {type === 'text' &&
                    <input className={"wheat-text vs-bg w-100 generic-input"}
                        type={type} value={value} onChange={(e) => { setValue(e.target.value); }}
                        disabled={isDisabled}
                    />
                }
                {type === 'number' &&
                    <input className={"wheat-text vs-bg w-100 generic-input"}
                        type={type} value={value} onChange={(e) => { setValue(e.target.value); }}
                        disabled={isDisabled}
                    />
                }
                {type === 'integer' &&
                    <input className={"wheat-text vs-bg w-100 generic-input"}
                        type='number' value={value} onChange={(e) => { setValue(e.target.value); }}
                        disabled={isDisabled}
                    />
                }
                {type === 'boolean' &&
                    <button className={"wheat-text vs-bg w-100 generic-input text-align-left"}
                        onClick={() => { setValue(!value); }}
                        disabled={isDisabled}
                    >
                        {value ? 'true' : 'false'}
                    </button>
                }
            </Col>
        </Row>
    );
};