import React, { useCallback, useEffect, useState } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { SimplePropsEntry } from '../SimplePropEntry';
import { BehaviorPropWrapper } from './BehaviorPropWrapper';
import './behavior.css';
import { rightAnchor, leftAnchor } from "./BehaviorStyles";
import { Row, Col } from 'react-bootstrap';
import { modService } from '../ModService';
import { BehaviorObjectPropWrapper } from './BehaviorObjectPropWrapper';

interface Props {
    value: any;
    redraw: () => void;
    setValue: (value: any) => void;
}

const attributes: { [key: string]: string[] } = {
    "BasicAttack": ['dir_angle_y', 'on_fail_blocked', 'dir_angle_xz', 'on_fail_armor', 'velocity_multiplier', 'on_success', 'use_caster_velocity', 'on_fail_immune', 'max damage', 'dont_apply_immune', 'min damage', 'radius', 'dir_force'],
    "TacArc": ['offset_z', 'near_height', 'use_attack_priority', 'target_self', 'use_target_position', 'method', 'target_enemy', 'offset_y', 'target_friend', 'affects_caster', 'blocked action', 'max targets', 'run_speed', 'upper_bound', 'action', 'clear_provided_target', 'far_width', 'near_width', 'far_height', 'use_picked_target', 'ignore_faction', 'check_env', 'miss action', 'radius', 'height', 'angle', 'max_range', 'max range', 'lower_bound', 'offset_x', 'angle_weight', 'target_team', 'first_within_range', 'include_faction', 'min range', 'distance_weight'],
    "And": ['behavior 1', 'behavior 2', 'behavior 3', 'behavior 4', 'behavior 5', 'behavior 6', 'behavior 7', 'behavior 8', 'behavior 9', 'behavior 10', 'behavior 11', 'behavior 12'],
    "ProjectileAttack": ['offset_z', 'spread_z_load_fudge', 'rotate_x_degrees', 'offset_y', 'num_intervals', 'use_high_arc', 'no_ally_check', 'clear_provided_target', 'LOT_ID', 'unauth_impact', 'track_target', 'spread_angle', 'projectile_speed', 'use_prediction', 'notify_target', 'spread_count', 'projectile_type', 'offset_x', 'track_radius', 'max_distance', 'use_mouseposit'],
    "Heal": ['health'],
    "Movement Switch": ['jetpack_action', 'moving_action', 'ground_action', 'double_jump_action', 'jump_action', 'imagination', 'falling_action', 'air_action'],
    "AoE": ['target_friend', 'target_self', 'use_target_as_caster', 'target_team', 'max targets', 'use_target_position', 'ignore_faction', 'include_faction', 'action', 'radius', 'target_enemy'],
    "PlayEffect": [], //['cant_attack', 'delay', 'cant_move', 'max damage', 'cant_turn', 'health', 'min damage', 'cant_equip', 'imagination', 'cant_interact'],
    "Immunity": ['immune_quickbuild_interrupts', 'immune_imagination_loss', 'immune_speed', 'immune_stun_rotate', 'immune_damage_over_time', 'immune_knockback', 'immune_interrupt', 'immune_stun_attack', 'immune_imagination_gain', 'immune_stun_equip', 'immune_stun_move', 'immune_basic_attack', 'immune_stun_interact'],
    "Damage Absorption": ['absorb_amount'],
    "Over Time": ['action', 'num_intervals', 'delay'],
    "Imagination": ['imagination'],
    "Target Caster": ['action'],
    "Stun": ['cant_jump', 'dont_terminate_interact', 'target_enemy', 'target_friend', 'ignore_immunity', 'cant_attack', 'duration', 'cant_equip', 'action', 'cant_use_item', 'stun_caster', 'radius', 'cant_move', 'cant_turn', 'cant_interact'],
    "Duration": ['angle', 'strength', 'delay', 'duration', 'action', 'originator_is_owner'],
    "Knockback": ['angle', 'relative', 'strength', 'time_ms', 'ignore_self', 'caster'],
    "AttackDelay": ['delay', 'num_intervals', 'ignore_interrupts', 'action'],
    "Car Boost": ['action', 'action_failed', 'active', 'time'],
    "FallSpeed": ['percent_slowed'],
    "RepairArmor": ['armor'],
    "Speed": ['attack_speed', 'run_speed', 'affects_caster'],
    "DarkInspiration": ['faction_list', 'action'],
    "LootBuff": ['scale'],
    "VentureVision": ['show_minibosses', 'show_collectibles', 'show_pet_digs'],
    "Spawn Object": ['updatePositionWithParent', 'LOT_ID', 'objectRadius', 'spawn_fail_action', 'distance'],
    "LayBrick": ['forcestack', 'maxbricks', 'duration', 'offset_forward', 'offset_up', 'templateID', 'forceflat', 'distance', 'forcelay'],
    "Switch": ['target_has_buff', 'action_false', 'action_true', 'isEnemyFaction', 'imagination', 'faction', 'distance'],
    "Buff": ['attack_speed', 'run_speed', 'armor', 'brain', 'life', 'imag'],
    "Jetpack": ['airspeed', 'warning_effect_id', 'bypass_checks', 'max_airspeed', 'vertical_velocity', 'enable_hover'],
    "Skill Event": ['target_caster'],
    "Consume Item": ['effect_id', 'action_not_consumed', 'consume_lot', 'action_consumed', 'run_speed', 'num_to_consume'],
    "SkillCastFailed": ['effect_id'],
    "ImitationSkunkStink": ['effect_id'],
    "ChangeIdleFlags": ['flags_on', 'flags_off'],
    "ApplyBuff": ['use_ref_count', 'cancel_on_death', 'cancel_on_damaged', 'target_caster', 'apply_on_teammates', 'buff_id', 'duration_secs', 'cancel_on_remove_buff', 'cancel_on_logout', 'cancel_on_ui', 'cancel_on_unequip', 'cancel_on_zone', 'add_immunity', 'ignore_uncast'],
    "Chain": ['behavior 1', 'behavior 2', 'behavior 3', 'chain_delay', 'behavior 4'],
    "ChangeOrientation": ['angle', 'behavior 1', 'relative', 'behavior 2', 'duration', 'behavior 3', 'to_point', 'to_target', 'to_angle', 'orient_caster'],
    "ForceMovement": ['forward', 'yaw', 'relative', 'yaw_abs', 'duration', 'hit_action_enemy', 'ignore_projectile_collision', 'timeout_action', 'left', 'hit_action', 'hit_action_faction', 'move_target', 'collide_with_faction'],
    "Interrupt": ['target', 'interrupt_charge', 'interrupt_attack', 'interrupt_block'],
    "AlterCooldown": ['add', 'amount'],
    "ChargeUp": ['max_duration', 'action'],
    "SwitchMultiple": ['behavior 1', 'value 1', 'value 3', 'behavior 2', 'value 4', 'behavior 3', 'distance_to_target', 'charge_time', 'value 2', 'behavior 4'],
    "Start": ['use_target', 'action'],
    "End": ['use_target', 'start_action'],
    "AlterChainDelay": ['new_delay', 'chain_action', 'target'],
    "Camera": ['pos_relative', 'pos_x', 'lookat_x', 'pos_z', 'lookat_z', 'pos_y', 'lock_camera', 'lookat_relative', 'lookat_y'],
    "RemoveBuff": ['buff_id', 'removie_immunity', 'remove_immunity'],
    "Grab": ['dir_angle_y', 'dir_force', 'dir_angle_xz'],
    "NPC Combat Skill": ['behavior 1', 'max range', 'min range', 'npc skill time'],
    "Block": ['block_damage', 'block_stuns', 'num_attacks_can_block', 'block_knockback', 'break_action'],
    "Verify": ['range', 'check_range', 'blocked_action', 'action', 'check_blocking'],
    "Taunt": ['threat to add'],
    "AirMovement": ['gravity_scale', 'ground_action', 'hit_action_enemy', 'timeout_action', 'goto_target', 'stop_input', 'y_velocity', 'hit_action', 'timeout_ms', 'x_velocity', 'use_collision_delay', 'move_target', 'z_velocity'],
    "SpawnQuickbuild": ['LOT_ID', 'objectRadius', 'offsetZ', 'offsetX', 'spawn_fail_action', 'repositionPlayer', 'offsetY', 'distance'],
    "PullToPoint": ['distance_offset', 'arc_height'],
    "DamageReduction": ['reduction_amount'],
    "PropertyTeleport": ['mapID', 'cancel_if_interacting'],
    "ClearTarget": ['clear_if_caster', 'action'],
    "TakePicture": ['save_to_disk', 'overlay_type', 'upload_to_web'],
    "Mount": ['mount'],
    "SkillSet": ['set_id'],
};

const booleans: string[] = [
    "affects_caster",
    "apply_on_teammates",
    "bypass_checks",
    "cancel_on_damaged",
    "cancel_on_death",
    "cancel_on_logout",
    "cancel_on_remove_buff",
    "cancel_on_ui",
    "cancel_on_unequip",
    "cancel_on_zone",
    "cant_jump",
    "cant_use_item",
    "caster",
    "charge_time",
    "check_blocking",
    "check_env",
    "clear_provided_target",
    "dont_apply_immune",
    "enable_hover",
    "forceflat",
    "goto_target",
    "gravity_scale",
    "ignore_immunity",
    "ignore_interrupts",
    "ignore_projectile_collision",
    "ignore_self",
    "immune_basic_attack",
    "immune_damage_over_time",
    "immune_interrupt",
    "immune_knockback",
    "immune_quickbuild_interrupts",
    "immune_speed",
    "immune_stun_attack",
    "immune_stun_equip",
    "immune_stun_interact",
    "immune_stun_move",
    "immune_stun_rotate",
    "interrupt_attack",
    "interrupt_block",
    "interrupt_charge",
    "isEnemyFaction",
    "move_target",
    "orient_caster",
    "originator_is_owner",
    "relative",
    "show_collectibles",
    "show_minibosses",
    "show_pet_digs",
    "spread_z_load_fudge",
    "stun_caster",
    "target",
    "target_caster",
    "target_friend",
    "target_self",
    "target_team",
    "to_angle",
    "to_point",
    "to_target",
    "track_target",
    "updatePositionWithParent",
    "use_attack_priority",
    "use_caster_velocity",
    "use_high_arc",
    "use_mouseposit",
    "use_picked_target",
    "use_prediction",
    "use_ref_count",
    "use_target",
    "use_target_as_caster",
    "use_target_position",
    "cant_attack",
    "cant_equip",
    "cant_interact",
    "cant_move",
    "cant_turn",
    "cant_use_item",
    "clear_provided_target",
    "dont_terminate_interact",
    "target_enemy",
];

const metadata: { [key: string]: { icon: string, important: string[] } } = {
    "BasicAttack": { icon: "lego-universe:3485", important: ["max damage", "min damage"] },
    "Duration": { icon: "lego-universe:4114", important: ["duration"] },
    "PlayEffect": { icon: "lego-universe:4109", important: [] },
    "Interrupt": { icon: "lego-universe:3675", important: [] },
    "Knockback": { icon: "lego-universe:2863", important: ["angle", "strength"] },
    "TacArc": { icon: "lego-universe:3373", important: ["max targets"] },
    "Stun": { icon: "lego-universe:4112", important: ["duration"] },
    "AlterCooldown": { icon: "lego-universe:2308", important: ["amount"] },
    "AlterChainDelay": { icon: "lego-universe:2308", important: ["amount"] },
    "And": { icon: "lego-universe:3641", important: [] },
    "Chain": { icon: "lego-universe:3488", important: [] },
    "Movement Switch": { icon: "lego-universe:3474", important: [] },
    "AirMovement": { icon: "lego-universe:3474", important: [] },
    "AttackDelay": { icon: "lego-universe:4708", important: ['delay', 'num_intervals'] },
    "ChangeOrientation": { icon: "lego-universe:3082", important: ['angle', 'duration'] },
    "ForceMovement": { icon: "lego-universe:4147", important: ['forward', 'yaw', 'duration'] },
    "Spawn Object": { icon: "lego-universe:2933", important: ['LOT_ID', 'distance'] },
    "ProjectileAttack": { icon: "lego-universe:4499", important: [] },
    "AoE": { icon: "lego-universe:3502", important: [] },
    "Switch": { icon: "lego-universe:3154", important: [] },
    "Start": { icon: "lego-universe:3638", important: [] },
    "End": { icon: "lego-universe:3635", important: [] },
    "Speed": { icon: "lego-universe:4491", important: [] },
    "Immunity": { icon: "lego-universe:4490", important: [] },
    "Skill Event": { icon: "lego-universe:3644", important: [] },
    "ChangeIdleFlags": { icon: "lego-universe:4225", important: [] },
    "Imagination": { icon: "lego-universe:3264", important: [] },
    "Heal": { icon: "lego-universe:3262", important: [] },
    "RepairArmor": { icon: "lego-universe:3263", important: [] },
    "Target Caster": { icon: "lego-universe:4724", important: [] },
};

export const Behavior: React.FC<NodeProps<Props>> = (props) => {
    const [behaviorTypes, setBehaviorTypes] = useState<string[]>([]);
    const [behaviorType, setBehaviorType] = useState<string>("");
    const [floatProperties, setFloatProperties] = useState<JSX.Element[] | null>(null);
    const [actions, setActions] = useState<string[] | null>(null);
    const [showFloatProperties, setShowFloatProperties] = useState<boolean>(false);
    const [selectedOutput, setSelectedOutput] = useState<string>("");
    const [iconUrl, setIconUrl] = useState<string>('')

    useEffect(() => {
        setBehaviorType(props.data.value.values["behavior-type"]);
    }, []);

    useEffect(() => {
        if (!behaviorType) {
            return;
        }

        setFloatProperties(null);
        setActions(null);

        onChangeBehaviorType();
    }, [behaviorType, showFloatProperties]);

    const saveSelectedOutput = (value: string) => {
        if (value) {
            if (!props.data.value["extra-data"]) {
                props.data.value["extra-data"] = {};
            }

            props.data.value["extra-data"]["selected-output"] = value;

            props.data.setValue(props.data.value);
        }
    };

    const onChangeBehaviorType = () => {
        // Print the state
        console.log("Behavior type changed to " + behaviorType);

        // The keys of attributes are the behavior types
        setBehaviorTypes(Object.keys(attributes));

        setIconUrl(modService.getIconUrl(metadata[behaviorType] ? metadata[behaviorType].icon : "lego-universe:3714"));

        if (!props.data.value.values["parameters"]) {
            props.data.value.values["parameters"] = {};
        }

        let floatProperties: JSX.Element[] = [];
        let actions: string[] = [];
        let propertyNames = Object.keys(props.data.value.values["parameters"]);

        let objects = 0;
        let objectCount = 0;

        let behaviorParameters = attributes[behaviorType];

        Object.keys(props.data.value.values["parameters"]).forEach((key) => {
            if (typeof props.data.value.values["parameters"][key] === "object") {
                objectCount++;
            }
        });

        // Add properties
        for (let i = 0; i < behaviorParameters.length; i++) {
            let name = behaviorParameters[i];
            if (name.includes("behavior") || name.includes("action") && !name.includes("faction") || name.startsWith("on_")) {
                if (!props.data.value.values["parameters"][name] || typeof props.data.value.values["parameters"][name] !== "string") {
                    props.data.value.values["parameters"][name] = "lego-universe:0";
                }

                /*actions.push(
                    <Handle key={name} type="source" className='node-anchor' style={{
                        ...rightAnchor,
                        top: (objects) * 45 + 55
                    }} position={Position.Right} id={name}>
                        {name}
                    </Handle>
                )*/

                actions.push(name);

                ++objects;
            }
            else {
                if (!props.data.value.values["parameters"][name] || typeof props.data.value.values["parameters"][name] !== "number") {
                    props.data.value.values["parameters"][name] = 0;
                }

                // Normalize name for display, capitalize first letter and replace underscores with spaces
                let title = name.charAt(0).toUpperCase() + name.slice(1);
                title = title.replace(/_/g, " ");

                let inputType = "number";

                if (booleans.includes(name)) {
                    const value = props.data.value.values["parameters"][name];
                    inputType = "checkbox";
                    // If the value is not a boolean, interpret the float/int as a boolean
                    if (typeof value !== "boolean") {
                        props.data.value.values["parameters"][name] = value !== 0;
                    }
                }

                floatProperties.push(
                    <BehaviorPropWrapper key={name} value={props.data.value} setValue={props.data.setValue} name={name} title={title} inputType={inputType} />
                );
            }
        }

        floatProperties.push(
            <BehaviorObjectPropWrapper
                key="effect"
                value={props.data.value}
                setValue={props.data.setValue}
                name="effect"
                title="Effect"
                objectType="effect"
                parameters={false}
            />
        );

        floatProperties.push(
            <BehaviorPropWrapper
                key="effectHandle"
                value={props.data.value}
                setValue={props.data.setValue}
                name="effect-handle"
                title="Effect Handle"
                inputType='text'
            />
        )

        // Remove any old properties
        for (let i = 0; i < propertyNames.length; i++) {
            if (!behaviorParameters.includes(propertyNames[i])) {
                delete props.data.value.values["parameters"][propertyNames[i]];
            }
        }

        setFloatProperties(floatProperties);
        setActions(actions);

        // See if we have a valid selected output
        if (props.data.value["extra-data"]) {
            const output = props.data.value["extra-data"]["selected-output"];

            if (actions.includes(output)) {
                setSelectedOutput(output);

                return;
            }
        }

        setSelectedOutput(actions.length > 0 ? actions[0] : "");
    }

    return (
        <>
            {!showFloatProperties &&
                <Row className='top-text'>
                    <Col md={12} className='center-text'>
                        <h5 className='wheat-text top-text-size'>{behaviorType}</h5>
                    </Col>
                </Row>
            }
            {floatProperties && actions && behaviorType &&
                <div className='vs-bg node-border' style={{ width: showFloatProperties ? actions.length > 0 ? 400 : 300 : actions.length > 0 ? 200 : 100, height: `${40 + Math.max(showFloatProperties ? (floatProperties.length + 2) * 50 : 0)}px` }}>
                    <div>
                        <Row>
                            <Col md={1}>
                                <Handle type="target" className='node-anchor' style={leftAnchor} position={Position.Left} id="in" />
                            </Col>
                            <Col md={showFloatProperties ? actions.length == 0 ? 6 : 5 : 2}>
                                {showFloatProperties &&
                                    <select className='wheat-text vs-bg w-100 header-font-size' value={behaviorType} onChange={(e) => {
                                        console.log(e.target.value);
                                        props.data.value.values["behavior-type"] = e.target.value;
                                        props.data.setValue(props.data.value);
                                        setBehaviorType(e.target.value);
                                    }}>
                                        {behaviorTypes.map((type) => {
                                            return <option className='wheat-text' key={type} value={type}>{type}</option>
                                        })}
                                    </select>
                                }
                                {!showFloatProperties &&
                                    <img src={iconUrl} alt={""} height={35} width={35} />
                                }
                            </Col>
                            {!showFloatProperties && actions.length == 0 &&
                                <Col md={2}></Col>
                            }
                            <Col md={showFloatProperties ? actions.length == 0 ? 4 : 5 : 2} className='left-text'>
                                <button onClick={() => {
                                    setShowFloatProperties(!showFloatProperties);
                                }} className='wheat-text vs-bg header-font-size b-0'>
                                    {showFloatProperties ? "↓" : "↑"}
                                </button>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginLeft: 15 }} md={12}>
                                {showFloatProperties && floatProperties}
                            </Col>
                            <Col md={0}>
                                {actions.length > 0 &&
                                    <Handle type="source" className='node-anchor' style={{
                                        ...rightAnchor
                                    }} position={Position.Right} id={"out"}>
                                        <select className='wheat-text w-100 output-selector' value={selectedOutput} onChange={(e) => {
                                            setSelectedOutput(e.target.value);
                                            saveSelectedOutput(e.target.value);
                                        }}
                                        >
                                            {actions.map((action) => {
                                                let noramlizedName = action;

                                                // If the name starts with "behavior ", remove it
                                                if (noramlizedName.startsWith("behavior ")) {
                                                    noramlizedName = noramlizedName.substring(9);
                                                }

                                                // Capitalize the first letter
                                                noramlizedName = noramlizedName.charAt(0).toUpperCase() + noramlizedName.slice(1);

                                                // Replace all "_" with " " and capitalize the next letter
                                                noramlizedName = noramlizedName.replace(/_/g, " ");

                                                for (let i = 0; i < noramlizedName.length; i++) {
                                                    if (noramlizedName.charAt(i) === " ") {
                                                        noramlizedName = noramlizedName.substring(0, i + 1) + noramlizedName.charAt(i + 1).toUpperCase() + noramlizedName.substring(i + 2);
                                                    }
                                                }

                                                return <option className='vs-bg wheat-text output-selector-option' key={action} value={action}>
                                                    {noramlizedName}
                                                </option>
                                            })}
                                        </select>
                                    </Handle>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </>
    );
}

/*
export class Behavior extends React.Component<NodeProps<Props>, State> {
    state: State = {
        behaviorTypes: [],
        behaviorType: "",
        floatProperties: [],
        actions: []
    }

    props: NodeProps<Props>;

    constructor(props: NodeProps<Props>) {
        super(props);

        this.props = props;
    }

    onTypeChanged() {
    }

    async componentDidMount() {
        this.setState({ behaviorType: this.props.data.value.values["behavior-type"] });

        this.setState({ behaviorTypes: await modService.getBehaviorTypes() });

        this.onChangeBehaviorType();
    }

    componentDidUpdate(prevProps: NodeProps<Props>, prevState: State) {
        if (prevState.behaviorType !== this.state.behaviorType || this.state.behaviorTypes !== prevState.behaviorTypes) {
            this.onChangeBehaviorType();
        }
    }

    onChangeBehaviorType() {
        if (!this.state.behaviorType) {
            return;
        }

        console.log("Behavior type changed to " + this.state.behaviorType);

        let behaviorParameters = modService.getBehaviorParameters(this.state.behaviorType);

        if (!behaviorParameters) {
            return;
        }

        if (!this.props.data.value.values["parameters"]) {
            this.props.data.value.values["parameters"] = {};
        }

        let floatProperties: JSX.Element[] = [];
        let actions: JSX.Element[] = [];

        let objects = 0;
        let objectCount = 0;

        Object.keys(this.props.data.value.values["parameters"]).forEach((key) => {
            if (typeof this.props.data.value.values["parameters"][key] === "object") {
                objectCount++;
            }
        });

        Object.keys(this.props.data.value.values["parameters"]).forEach((key) => {
            // If the property is not in the behavior parameters, skip it
            if (!behaviorParameters.includes(key)) {
                return;
            }

            if (typeof this.props.data.value.values["parameters"][key] !== "number") {
                const type = typeof this.props.data.value.values["parameters"][key];
                if (type === "object" || type === "string") {
                    actions.push(
                        <Handle key={key} type="source" className='node-anchor' style={{
                            ...rightAnchor,
                            top: (objects) * 45 + 35
                        }} position={Position.Right} id={key}>
                            {key}
                        </Handle>
                    )

                    ++objects;
                }

                return;
            }

            floatProperties.push(
                <BehaviorPropWrapper key={key} value={this.props.data.value} setValue={this.props.data.setValue} name={key} title={key} />
            );
        });

        // Add any new properties, remove any old ones which have their value set at 0

        this.setState({ floatProperties: floatProperties, actions: actions });
    }

    render() {
        return (
            <>
                {this.state.floatProperties && this.state.actions &&
                    <div className='vs-bg node-border' style={{ width: 500, height: `${40 + Math.max(this.state.floatProperties.length * 40, this.state.actions.length * 40)}px` }}>
                        <Handle type="target" className='node-anchor' style={leftAnchor} position={Position.Left} id="in" />
                        <div>
                            <select className='wheat-text vs-bg' value={this.state.behaviorType} onChange={(e) => {
                                console.log(e.target.value);
                                this.props.data.value.values["behavior-type"] = e.target.value;
                                this.props.data.setValue(this.props.data.value);
                                this.setState({ behaviorType: e.target.value });
                            }}>
                                {this.state.behaviorTypes.map((type) => {
                                    return <option className='wheat-text' key={type} value={type}>{type}</option>
                                })}
                            </select>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={10}>
                                    {this.state.floatProperties}
                                </Col>
                                <Col md={1}>
                                    {this.state.actions}
                                </Col>
                            </Row>
                        </div>
                    </div>}
            </>
        );
    }
}

*/