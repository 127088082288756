import React, { useEffect, useState } from 'react';
import '../components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from '../ModService';
import { ResourceExplorerFilePreview } from './ResourceExplorerFilePreivew';
import { JsxElement } from 'typescript';
import { GenericText } from '../GenericText';

interface ResourceExplorerDirectoryProps {
    path: string;
    onSelect: () => void;
}

export const ResourceExplorerDirectory: React.FC<ResourceExplorerDirectoryProps> = ({ path, onSelect }) => {
    const name = path.split("/").pop();

    return (
        <Row>
            <p className='pointer monospace wheat-text w-100'
                onClick={onSelect}
            >{name}</p>
        </Row>
    );
};