import React, { useState } from 'react';

interface MyTooltipProps {
    children: React.ReactNode;
    tooltipText: string | undefined;
}

const MyTooltip: React.FC<MyTooltipProps> = ({ children, tooltipText }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {isHovered && <p className="w-100 vs-bg white-text">{tooltipText}</p>}
        </span>
    );
};

export default MyTooltip;