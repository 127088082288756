import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';

interface ObjectProps {
    name: string;
    type: string;
    value: string;
    setValue: (value: string) => void;
    onClose: () => void;
}

const tabels: {[key: string]: {table: string, column: string}} = {
    "object": {table: "Objects", column: "id"},
    "effect": {table: "BehaviorEffect", column: "effectID"},
    "animation-group": {table: "Animations", column: "animationGroupID"},
}

export const ObjectCard: React.FC<ObjectProps> = ({ name, type, value, setValue, onClose }) => {
    const [show, setShow] = useState<boolean>(true);
    const [results, setResults] = useState<string[]>([]);
    const [valid, setValid] = useState<boolean>(false);
    const [coreNumber, setCoreNumber] = useState<number>(0);
    const [internalValue, setInternalValue] = useState<string>(value);

    const validate = (val: number) => {
        modService.sql(`SELECT COUNT(*) as count FROM ${tabels[type].table} WHERE ${tabels[type].column} = '${val}'`).then(data => {
            if (data.length === 0) {
                setValid(false);
                return;
            }

            const count = data[0]["count"] as number;

            setValid(count > 0);
        });
    }
    
    useEffect(() => {
        if (internalValue.startsWith("lego-universe:")) {
            try {
                const coreNumber = parseInt(internalValue.split(":")[1]);

                setCoreNumber(coreNumber);
                validate(coreNumber);
            } catch (error) {
                console.log(error);
            }
        }
    
        modService.queryMod(type).then(data => {
            console.log(data);
            // Take the set of keys from data, data is a map
            setResults(Object.keys(data));
        });
    }, []);

    return (
        <Modal show={show} onHide={() => {
            if (value !== internalValue) {
                setValue(internalValue);
            }
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <Row className='center-text'>
                    <p className='wheat-text'>{name} ({type}/{tabels[type].table})</p>
                </Row>
                {results.length > 0 && 
                <Row>
                    <Col md={3}>
                        <p className='wheat-text'>Resource</p>
                    </Col>
                    <Col md={9}>
                        <select className='wheat-text vs-bg w-100' 
                            onChange={(e) => {
                                setValue(e.target.value);
                                setShow(false);
                                onClose();
                            }}
                            value={internalValue}
                            >
                            {!results.includes(internalValue) && <option value='' className='wheat-text'>Select</option>}
                            
                            {results.map((result, index) => {
                                return <option key={index} value={result}>{result}</option>
                            })}
                        </select>
                    </Col>
                </Row>
                }
                <Row>
                    <Col md={3}>
                        <p className='wheat-text'>lego-universe:</p>
                    </Col>
                    <Col md={9}>
                        <input className={"wheat-text vs-bg w-100 no-border " + (valid ? "success" : "danger")}
                            type='number'
                            value={coreNumber}
                            onChange={(e) => {
                                const val = parseInt(e.target.value);

                                // If it's not a number, set it to 0
                                if (isNaN(val)) {
                                    setValid(false);
                                    setCoreNumber(0);
                                    return;
                                }

                                setCoreNumber(val);
                                setInternalValue(`lego-universe:${e.target.value}`);
                                validate(val);
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </Modal>
    );
};