import React, { useEffect, useState } from 'react';
import '../components.css';
import './ErrorScreen.css';
import { Row, Col } from 'react-bootstrap';
import { GuideHandler } from '../Guide/GuideHandler';

interface ErrorScreenProps {
    title: string;
    message: string;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ title, message }) => {
    const [show, setShow] = useState(true);

    const paragraphs = message.split('\n').map((line, index) => {
        // Count the number of starting spaces
        const indentLevel = line.search(/\S/);
        return <p
        key={index}
        className='error-screen-paragraph'
        style={{ textIndent: indentLevel + 'em' }}
        >{line}</p>;
    });

    return (
        <Row className='error-screen-container'>
            <Row className='error-screen-title-container'>
                <Col className='error-screen-title'>{title}</Col>
                <Col className='error-screen-close' onClick={() => setShow(!show)}>{show ? 'Hide' : 'Show'}</Col>
            </Row>
            <Row id="error-message" className='error-screen-message-container'>
                {show &&
                <Col className='error-screen-message'>
                    {paragraphs}
                </Col>
                }
            </Row>
            <GuideHandler element='error-message' />
        </Row>
    );
};