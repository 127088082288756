import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface AccountModalProps {
    show: boolean;
    onHide: () => void;
    onLogout: () => void;
    onDeleteAccount: () => void;
    onDownloadData: () => void;
}

const AccountModal: React.FC<AccountModalProps> = ({ show, onHide, onLogout, onDeleteAccount, onDownloadData }) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const handleDeleteAccount = () => {
        setShowConfirmDelete(true);
    };

    const confirmDeleteAccount = () => {
        onDeleteAccount();
        setShowConfirmDelete(false);
        onHide();
    };

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Account Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="danger" onClick={handleDeleteAccount}>
                        Delete Account
                    </Button>
                    <Button variant="primary" onClick={onDownloadData}>
                        Download Data
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onLogout}>
                        Logout
                    </Button>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete your account and all the accompanying data? <strong>This action cannot be undone.</strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={confirmDeleteAccount}>
                        Confirm Delete
                    </Button>
                    <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AccountModal;