import './components.css';

interface GenericErrorProps {
    title: string;
}

export const GenericError: React.FC<GenericErrorProps> = ({ title }) => {
    return (  
        <p className='w-100 vs-bg red-text'>{title}</p>
    );
};