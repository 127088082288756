import { useParams } from 'react-router-dom';
import { ModelViewer } from './ModelViewer';
import { modService } from '../ModService';
import { WorldViewer } from './WorldViewer';

export const WorldEmbedded = () => {
    const { zone } = useParams<{ zone: string }>();

    if (!zone) {
        return null;
    }

    return (
        <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
            <WorldViewer zone={zone} />
        </div>
    );
}