import './App.css';
import { NewMod } from './components/NewMod';

function NewModHome() {
    return (
        <div className='row' id='main'>
            <div className='col-12 vs-bg'>
                <div className='explorer-container'>
                    <NewMod card={false} save={() => {}} />
                </div>
            </div>
        </div>
    );
}

export default NewModHome;
