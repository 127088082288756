import React from 'react';

interface HotKeyHintProps {
    keys: string[];
}

export const HotKeyHint: React.FC<HotKeyHintProps> = ({ keys }) => {
    // + seperated keys
    const keyString = keys.join('+');

    return (
        <div className="hotkey-hint">
            {keyString}
        </div>
    );
}