import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';
import { GenericInput } from './GenericInput';
import { GenericBtn } from './GenericBtn';
import { NewProject } from './NewProject';

interface CreateProjectCardProps {
    onSave: (name: string) => void;
    onClose: () => void;
}

export const CreateProjectCard: React.FC<CreateProjectCardProps> = ({ onSave, onClose }) => {
    const [show, setShow] = useState<boolean>(true);

    return (
        <Modal show={show} onHide={() => {
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <NewProject card={true} onSave={onSave} />
            </Card>
        </Modal>
    );
};