import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Markdown from 'react-markdown'
import './Guide.css'
import { GuideHandlers } from "./GuideHandler";

interface GuidePage {
    title: string;
    highlight: string[];
    markdown: string;
}

interface GuideProps { }

const pages: GuidePage[] = [
    {
        title: "1. Welcome",
        highlight: ['guide-panel', 'next-button'],
        markdown: "guides/1.0-welcome.md"
    },
    {
        title: "1.1 Edtior",
        highlight: ['editor-panel', 'next-button'],
        markdown: "guides/1.1-editor.md"
    },
    {
        title: "1.2 Explorer",
        highlight: ['explorer-panel', 'next-button'],
        markdown: "guides/1.2-explorer.md"
    },
    {
        title: "1.3 Logger",
        highlight: ['logger-panel', 'next-button'],
        markdown: "guides/1.3-logger.md"
    },
    {
        title: "2. Packages",
        highlight: ['next-button'],
        markdown: "guides/2.0-packages.md"
    },
    {
        title: "2.1 Creating Packages",
        highlight: ['file-dropdown', 'new-package-button', 'create-mod-name', 'create-mod-save', 'next-button'],
        markdown: "guides/2.1-creating-packages.md"
    },
    {
        title: "2.2 Selecting Packages",
        highlight: ['package-selector', 'next-button'],
        markdown: "guides/2.2-select-package.md"
    },
    {
        title: "3. Mods",
        highlight: ['next-button'],
        markdown: "guides/3.0-mods.md"
    },
    {
        title: "3.1 Creating Mods",
        highlight: ['file-dropdown', 'new-mod-button', 'create-mod-save', 'next-button'],
        markdown: "guides/3.1-creating-mods.md"
    },
    {
        title: "3.2 Selecting Mods",
        highlight: ['package-files', 'next-button'],
        markdown: "guides/3.2-selecting-mods.md"
    },
    {
        title: "3.3 Editor Overview",
        highlight: ['editor-panel', 'next-button'],
        markdown: "guides/3.3-editor-overview.md"
    },
    {
        title: "3.4 Default Values",
        highlight: ['render-field-nametag', 'next-button'],
        markdown: "guides/3.4-default-values.md"
    },
    {
        title: "3.5 Values and Errors",
        highlight: ['render-field-components', 'next-button', 'error-message'],
        markdown: "guides/3.5-values-and-errors.md"
    },
    {
        title: "3.6 References",
        highlight: ['render-field-components', `render-field-id`, 'next-button'],
        markdown: "guides/3.6-refrences.md"
    },
    {
        title: "4. Deployment",
        highlight: ['deployment-dropdown', 'deploy-button', 'start-servers-button'],
        markdown: "guides/4.0-deployment.md"
    }
];

export let HighlightedElements: string[] = [];

export let HighlightListeners: { [key: string]: (value: boolean) => void } = {};

export const Guide: React.FC<GuideProps> = () => {
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [markdown, setMarkdown] = useState<string>("");

    // Fetch the guide page from Cookie
    useEffect(() => {
        const page = parseInt(localStorage.getItem("guidePage") || "0");
        setPageIndex(page);
    }, []);

    const handleNext = () => {
        setPageIndex((prevIndex) => {
            const newIndex = Math.min(prevIndex + 1, pages.length - 1);
            localStorage.setItem("guidePage", newIndex.toString());
            return newIndex;
        });
    };

    const handlePrev = () => {
        setPageIndex((prevIndex) => {
            const newIndex = Math.max(prevIndex - 1, 0);
            localStorage.setItem("guidePage", newIndex.toString());
            return newIndex;
        });
    };

    useEffect(() => {
        fetch(pages[pageIndex].markdown)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(text);
            });

        for (const element of HighlightedElements) {
            if (HighlightListeners[element]) {
                HighlightListeners[element](false);
            }
        }

        HighlightedElements = [];
        for (const element of pages[pageIndex].highlight) {
            HighlightedElements.push(element);
        }

        for (const element of HighlightedElements) {
            if (HighlightListeners[element]) {
                HighlightListeners[element](true);
            }
        }
    }, [pageIndex]);

    return (
        <div className="logger vs-bg dragable-size">
            <div className="h-90">
                <div className="scrollable-h-100">
                    <table className="log-container markdown-container">
                        <Markdown className={'markdown-text'}>{markdown}</Markdown>
                    </table>
                </div>
            </div>
            <Row className="justify-content-cente1r h-10 d-flex align-items-center connected-buttons" style={{ padding: "0 10px" }}>
                    <Button
                        className="guide-button-left text-nowrap"
                        variant="secondary"
                        onClick={handlePrev}
                        id="prev-button"
                        disabled={pageIndex <= 0} /* Disable if no previous page */
                    >
                        {pageIndex > 0 ? pages[pageIndex - 1].title : "Previous"}
                    </Button>
                    <select
                        id="guide-page-select"
                        value={pageIndex}
                        onChange={(e) => {
                            const newIndex = parseInt(e.target.value);
                            setPageIndex(newIndex);
                            localStorage.setItem("guidePage", newIndex.toString());
                        }}
                        className="form-select guide-button-middle text-nowrap"
                    >
                        {pages.map((page, index) => (
                            <option key={index} value={index}>
                                {page.title}
                            </option>
                        ))}
                    </select>
                    <Button
                        className="guide-button-right text-nowrap"
                        variant="primary"
                        onClick={handleNext}
                        id="next-button"
                        disabled={pageIndex >= pages.length - 1} /* Disable if no next page */
                    >
                        {pageIndex < pages.length - 1 ? pages[pageIndex + 1].title : "Next"}
                    </Button>
                <GuideHandlers elements={['prev-button', 'next-button', 'guide-page-select']} />
            </Row>
        </div>
    );
}