import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';
import { GenericEnum } from './GenericEnum';
import { GenericInput } from './GenericInput';
import { GenericIcon } from './GenericIcon';
import { FileUploader } from 'react-drag-drop-files';
import { GenericFile } from './GenericFile';
import Cookies from 'js-cookie';

interface IconSelectorProps {
    name: string;
    value: string;
    setValue: (value: string) => void;
    onClose: () => void;
}

export const IconSelector: React.FC<IconSelectorProps> = ({ name, value, setValue, onClose }) => {
    const [show, setShow] = useState<boolean>(true);
    const [results, setResults] = useState<string[]>([]);
    const [valid, setValid] = useState<boolean>(false);
    const [coreNumber, setCoreNumber] = useState<number>(0);
    const [iconUrl, setIconUrl] = useState<string>("");
    const [internalValue, setInternalValue] = useState<string>(value);

    const validate = (val: number) => {
        modService.sql(`SELECT COUNT(*) as count FROM Icons WHERE IconID = '${val}'`).then(data => {
            if (data.length === 0) {
                setValid(false);
                return;
            }

            const count = data[0]["count"] as number;

            setValid(count > 0);
        });
    }

    const queryIcons = () => {
        modService.queryMod("icon").then(data => {
            console.log(data);
            // Take the set of keys from data, data is a map
            setResults(Object.keys(data));
        });
    };
    
    useEffect(() => {
        if (internalValue.startsWith("lego-universe:")) {
            try {
                const coreNumber = parseInt(internalValue.split(":")[1]);

                setCoreNumber(coreNumber);
                validate(coreNumber);
            } catch (error) {
                console.log(error);
            }
        }

        queryIcons();

        setIconUrl(modService.getIconUrl(internalValue));
    }, []);

    const handleFile = (file: File) => {
        const pack = Cookies.get("package");

        if (!pack) {
            alert("Please select a package");
            return;
        }

        const path = `icons/${file.name}`;

        modService.uploadFile(file, pack, path).then(finalPath => {
            if (finalPath === "") {
                alert("Error uploading file");
                return;
            }
            
            const mod = {
                "name": `${pack}:icons:${file.name.split(".")[0]}`,
                "type": "icon",
                "action": "add",
                "values": {
                    "source": finalPath
                }
            }

            modService.createMod(mod).then(data => {
                if (data.uid === 0) {
                    alert("Error creating new file");
                    return;
                }

                queryIcons();
                setInternalValue(mod.name);
                setValue(mod.name);
                setCoreNumber(0);
                setIconUrl(modService.getIconUrl(mod.name));
            });
        });
    };

    return (
        <Modal show={show} onHide={() => {
            if (value !== internalValue) {
                setValue(internalValue);
            }
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <Row className='center-text'>
                    <p className='wheat-text'>{name} (icon/Icons)</p>
                </Row>
                {results.length > 0 &&
                    <GenericEnum title="Resource" options={results} value={internalValue} setValue={(value) => {
                        setValue(value);
                        setShow(false);
                        onClose();
                    }} />
                }
                    <GenericInput title="lego-universe" type="number" value={coreNumber} setValue={(value) => {
                        const val = parseInt(value);

                        // If it's not a number, set it to 0
                        if (isNaN(val)) {
                            setValid(false);
                            setCoreNumber(0);
                            return;
                        }

                        setCoreNumber(val);
                        const newValue = `lego-universe:${value}`;
                        setInternalValue(newValue);
                        validate(val);
                        setIconUrl(modService.getIconUrl(newValue));

                    }} />
                {
                    <GenericIcon title="Icon" url={iconUrl} alt={internalValue} />
                }
                {
                    <GenericFile title="Upload Icon" types={["PNG", "DDS"]} onUpload={handleFile} />
                }
            </Card>
        </Modal>
    );
};