import { useCallback, useState } from 'react';
import { Handle, Position, EdgeProps, getBezierPath, getStraightPath, getSimpleBezierPath, BaseEdge, useReactFlow, EdgeLabelRenderer } from 'reactflow';
import { SimplePropsEntry } from '../SimplePropEntry';
import './behavior.css';
import { rightAnchor } from "./BehaviorStyles";

interface Props {
    name: string;
    siblings: number;
    nr: number;
}

// Five colors for the edges
const siblingColors = [
    // Light Blue
    "#ADD8E6",
    // Light Coral
    "#F08080",
    // Light Sky Blue
    "#87CEFA",
    // Light Green
    "#90EE90",
    // Light Pink
    "#FFB6C1"
];


export const BehaviorEdge: React.FC<EdgeProps<Props>> = (props) => {
    const { id, sourceX, sourceY, targetX, targetY, data } = props;
    const siblings = data?.siblings ?? 0;
    const siblingNr = data?.nr ?? 0;

    const [edgePath, labelX, labelY] = getSimpleBezierPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    let noramlizedName = data?.name || "Unnamed Edge";

    // If the name starts with "behavior ", remove it
    if (noramlizedName.startsWith("behavior ")) {
        noramlizedName = noramlizedName.substring(9);
    }

    // Capitalize the first letter
    noramlizedName = noramlizedName.charAt(0).toUpperCase() + noramlizedName.slice(1);

    // Replace all "_" with " " and capitalize the next letter
    noramlizedName = noramlizedName.replace(/_/g, " ");

    for (let i = 0; i < noramlizedName.length; i++) {
        if (noramlizedName.charAt(i) === " ") {
            noramlizedName = noramlizedName.substring(0, i + 1) + noramlizedName.charAt(i + 1).toUpperCase() + noramlizedName.substring(i + 2);
        }
    }

    const yPos = (labelY);

    const siblingPos = (siblings - 1) / 2 - siblingNr;
    const siblingColor = (siblings === 1 && siblingNr === 0) ? "wheat" : siblingColors[siblingNr % siblingColors.length];
    
    return (
        <>
            <BaseEdge
                id={id}
                path={edgePath}
            />
            <EdgeLabelRenderer>
                <div style={
                    {
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${yPos}px)`,
                        pointerEvents: 'all',
                    }
                } className="nodrag nopan">
                    <p className='wheat-text' style={{ top: `${(siblingPos*20)}px`, position: 'absolute', transform: `translate(-50%, -50%)`, fontSize: "12px", width: "200px", color: siblingColor }}>
                        {noramlizedName}
                    </p>
                </div>
            </EdgeLabelRenderer>
        </>
    );
};