import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import './CookieBanner.css';

const CookieBanner: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const isCookieAccepted = localStorage.getItem('cookiesAccepted');
        if (!isCookieAccepted) {
            setIsVisible(true); // Show banner if cookies haven't been accepted
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-banner">
            <Container>
                <p>
                    This website uses only functional cookies for essential website operations.
                    It has nothing to sell you, uses no third-party cookies, and doesn't track you.
                    By using this website, you agree to the use of cookies as outlined in the <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                </p>
                <Button variant="primary" onClick={handleAccept}>
                    Got it!
                </Button>
            </Container>
        </div>
    );
};

export default CookieBanner;
