import React, { useState, useEffect, useRef } from 'react';
import { GenericText } from '../GenericText';
import { modService } from '../ModService';
import { GenericBtn } from '../GenericBtn';
import { GenericFile } from '../GenericFile';
import { GenericIcon } from '../GenericIcon';
import { GenericInput } from '../GenericInput';
import { Template, ObjectProperty } from './TypeTemplate'; // Import the types
import { GenericEnum } from '../GenericEnum';
import { Col, Dropdown, DropdownButton, Row, Tooltip } from 'react-bootstrap';
import { LocaleCard } from '../LocaleCard';
import { ResourceExplorer } from '../Resources/ResourceExplorer';
import { Icon, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MyTooltip from './MyTooltip';
import "./graphical.css";
import { OpenFile } from '../OpenFile';
import { FormatEnumOption } from '../Formatters';
import { FaCog } from 'react-icons/fa';
import { LiaCogSolid } from 'react-icons/lia';
import { BsQuestionCircle } from "react-icons/bs";
import { GuideHandler } from '../Guide/GuideHandler';

interface GraphicalEditorProps {
    value: any;
    setValue: (value: any) => void;
    props: InternalProps;
    openFile: OpenFile;
    scope: (number | string)[];
    readonly: boolean;
}

const isRequired = (property: ObjectProperty) => {
    return typeof property.required === 'boolean' ? property.required : true;
}

const isNullable = (property: ObjectProperty) => {
    return typeof property.nullable === 'boolean' ? property.nullable : false;
}

const hasDefault = (property: ObjectProperty) => {
    return typeof property.default !== 'undefined';
}

const normalizeTitle = (title: String | null) => {
    if (title === undefined || title === null) {
        return '';
    }
    // Capitalize the first letter of every word, replace '-' with ' ', and remove any underscores
    return title.split('-').join(' ').replace(/_/g, '').replace(/\b\w/g, (c) => c.toUpperCase());
}

const getDefault = (property: ObjectProperty) => {
    if (hasDefault(property)) {
        return property.default;
    }

    if (!isRequired(property)) {
        return undefined;
    }

    if (isNullable(property)) {
        return null;
    }

    switch (property.type) {
        case 'string':
            return '';
        case 'integer':
            return 0;
        case 'number':
            return 0;
        case 'boolean':
            return false;
        case 'enum':
            return property.enum instanceof Array ? property.enum[0] : Object.keys(property.enum as any)[0];
        case 'array':
            return [];
        case 'object':
            return {};
        case 'resource':
            return '';
        case 'id':
        case 'id-lax':
        case 'id-any':
            return 'lego-universe:0';
        default:
            return '';
    }
}

const getDefaultFilled = (property: ObjectProperty) => {
    console.log("Getting default filled for:", property);
    switch (property.type) {
        case 'string':
            return '';
        case 'integer':
            return 0;
        case 'number':
            return 0;
        case 'boolean':
            return false;
        case 'enum':
            return property.enum instanceof Array ? property.enum[0] : Object.keys(property.enum as any)[0];
        case 'array':
            return [];
        case 'object':
            return {};
        case 'resource':
            return '';
        case 'id':
        case 'id-lax':
        case 'id-any':
            return 'lego-universe:0';
        default:
            return '';
    }
}

export interface RenderFieldProps {
    fieldKey: string;
    property: ObjectProperty;
    value: any;
    setValue: (value: any) => void;
    props: InternalProps;
    openFile: OpenFile;
    scope: (number | string)[];
    showAdvanced?: boolean;
    readonly?: boolean;
}

const RenderField: React.FC<RenderFieldProps> = ({
    fieldKey,
    property,
    value,
    setValue,
    props,
    openFile,
    scope,
    showAdvanced,
    readonly
}) => {
    const key = fieldKey;

    const [showResourceSelector, setShowResourceSelector] = useState<boolean>(false);

    if (typeof value !== 'undefined') {
        console.log("Rendering field:", key, "Property:", property, "Value:", value);
    }
    if (isRequired(property) && value === undefined) {
        value = getDefault(property);
    }

    switch (property.type) {
        case 'string':
            return (
                <div className='array-container'>
                <GenericInput
                    type="text"
                    value={value}
                    setValue={(e) => setValue(e)}
                    titleClassNames='left-text'
                    readonly={readonly}
                />
                </div>
            );
        case 'integer':
        case 'number':
            return (
                <div className='array-container'>
                <GenericInput
                    type="number"
                    value={value}
                    setValue={(e) => setValue(Number(e))}
                    titleClassNames='left-text'
                    readonly={readonly}
                />
                </div>
            );
        case 'boolean':
            return (
                <div className='array-container'>
                <GenericInput
                    type="boolean"
                    value={value}
                    setValue={(e) => setValue(e)}
                    titleClassNames='left-text'
                    readonly={readonly}
                />
                </div>
            );
        case 'enum':
            // Convert property.enum to a string array if it's an object
            const array = property.enum instanceof Array ? property.enum : Object.keys(property.enum as any);
            return (
                <div className='array-container'>
                <GenericEnum
                    options={array}
                    value={value}
                    setValue={(e) => setValue(e)}
                    titleClassNames='left-text'
                    formatOption={(option) => FormatEnumOption(option)}
                    readonly={readonly}
                />
                </div>
            );
        case 'array':
            return (
                <div className='array-container'>
                    {typeof value === 'undefined' && <GenericText title='Empty array' />}
                    {Array.isArray(value) && (
                        (value || []).map((item: any, index: number) => (
                            <div key={index} className='array-element-border'>
                                <RenderField
                                    fieldKey={`${key}[${index}]`}
                                    property={property['array-type']!}
                                    value={item}
                                    setValue={(subValue) => {
                                        let newValue = [...value];
                                        newValue[index] = subValue;
                                        setValue(newValue);
                                    }}
                                    props={props}
                                    openFile={openFile}
                                    scope={[...scope, index]}
                                    readonly={readonly}
                                />
                                <GenericBtn
                                    readonly={readonly}
                                    danger={true}
                                    onClick={() => {
                                        let newValue = [...value];
                                        newValue.splice(index, 1);
                                        setValue(newValue);
                                    }} title='Remove Element' />
                            </div>
                        ))
                    )}
                    <GenericBtn
                        onClick={() => setValue([...(value || []), getDefault(property['array-type']!)])}
                        title='Add Element'
                        readonly={readonly}
                        secondary={true}
                    />
                </div>
            );
        case 'object':
            return (
                <div className=''>
                    {property['object-properties'] && Object.keys(property['object-properties']).map((subKey) => {
                        let subProperty = property['object-properties']![subKey];

                        if (!showAdvanced && subProperty['hide-by-default']) {
                            return <> </>;
                        }

                        let aliases = subProperty.aliases || [];
                        // Check if subKey exists in value, if not, check for aliases
                        // Default to using the subKey
                        let subValueKey = value[subKey];
                        if (subValueKey === undefined) {
                            subValueKey = aliases.find((alias) => value[alias] !== undefined) || subKey;
                        }
                        else {
                            subValueKey = subKey;
                        }

                        return (
                            <div className='left-text' key={subKey}>
                                <RowRenderField
                                    fieldKey={subValueKey}
                                    property={subProperty}
                                    value={value[subValueKey]}
                                    setValue={(eValue) => {
                                        let newValue = { ...value };
                                        newValue[subValueKey] = eValue;
                                        setValue(newValue);
                                    }}
                                    props={props}
                                    renderTitle={true}
                                    openFile={openFile}
                                    scope={[...scope, subValueKey]}
                                    readonly={readonly}
                                />
                            </div>
                        );
                    })}
                    {/* Arbatrary user entered object key and values, not an array */}
                    {property['array-type'] && (
                        <>
                            {Object.keys(value).map((subKey, key) => (
                                <Row key={key}>
                                    <GenericInput
                                        title='Key'
                                        type='text'
                                        value={subKey}
                                        setValue={(e) => {
                                            let newValue = { ...value };
                                            delete newValue[subKey];
                                            newValue[e] = value[subKey];
                                            setValue(newValue);
                                        }}
                                        readonly={readonly}
                                    />
                                    <RowRenderField
                                        fieldKey={subKey}
                                        property={property['array-type']!}
                                        value={value[subKey]}
                                        setValue={(eValue) => {
                                            let newValue = { ...value };
                                            newValue[subKey] = eValue;
                                            setValue(newValue);
                                        }}
                                        props={props}
                                        openFile={openFile}
                                        scope={[...scope, subKey]}
                                        readonly={readonly}
                                    />
                                    <GenericBtn
                                        onClick={() => {
                                            let newValue = { ...value };
                                            delete newValue[subKey];
                                            setValue(newValue);
                                        }}
                                        title='Remove'
                                        readonly={readonly}
                                    />
                                </Row>
                            ))}
                            <GenericBtn
                                onClick={() => setValue({ ...value, [Object.keys(value).length]: getDefault(property['array-type']!) })}
                                title='Add'
                                readonly={readonly}
                            />
                        </>
                    )}
                </div>
            );
        case 'resource':
            return (
                <div className='array-container'>
                    <GenericInput
                        type='text'
                        value={value}
                        setValue={(e) => setValue(e)}
                        readonly={readonly}
                    />
                </div>
            );
        case 'locale':
            /*const valueString = (typeof value === 'string') ? value : (value["en_GB"] ?? value["en_US"] ?? value["de_DE"] ?? '');
            return (
                <GenericBtn
                    title={valueString}
                    onClick={() => {
                        props.setLocaleTitle(key);
                        props.setLocaleValue(value);
                        props.setShowLocale(true);
                    }}
                />
            )*/
            return (
                <div className='array-container'>
                <GenericInput
                    type='text'
                    value={value}
                    setValue={(e) => setValue(e)}
                    readonly={readonly}
                />
                </div>
            )
        case 'id':
        case 'id-lax':
        case 'id-any':
            // Either a string, number or object, allow user to select which type via an enum
            let options = ['reference', 'number', 'object'];
            if (property.type === 'id-lax') {
                options.push('boolean');
            }
            else if (property.type === 'id-any') {
                options.push('boolean');
                options.push('string');
            }
            let selectedType = 'reference';
            if (typeof value === 'number') {
                selectedType = 'number';
            }
            else if (typeof value === 'object') {
                selectedType = 'object';
            }
            else if (typeof value === 'boolean') {
                selectedType = 'boolean';
            }
            else if (typeof value === 'string') {
                // If it contains a ':' it's a reference
                if (value.includes(':')) {
                    selectedType = 'reference';
                }
                else {
                    selectedType = 'string';
                }
            }
            return (
                <div className='array-container'>
                    <Row>
                        <Col md={3}>
                            <GenericEnum
                                titleClassNames='left-text'
                                options={options}
                                value={selectedType}
                                setValue={(e) => {
                                    switch (e) {
                                        case 'reference':
                                            setValue('lego-universe:0');
                                            break;
                                        case 'number':
                                            setValue(0);
                                            break;
                                        case 'object':
                                            setValue({
                                                type: property.types?.length ? property.types[0] : 'object'
                                            });
                                            break;
                                        case 'boolean':
                                            setValue(false);
                                            break;
                                        case 'string':
                                            setValue('');
                                            break;
                                    }
                                }}
                                readonly={readonly}
                            />
                        </Col>
                        <Col md={9}>
                            {(typeof value === 'string' || typeof value === 'undefined') && (
                                <GenericInput
                                    type='reference'
                                    value={value}
                                    setValue={(e) => setValue(e)}
                                    readonly={readonly}
                                />
                            )}
                            {typeof value === 'number' && (
                                <GenericInput
                                    type='number'
                                    value={value}
                                    setValue={(e) => setValue(Number(e))}
                                    readonly={readonly}
                                />
                            )}
                            {typeof value === 'object' && (
                                <>
                                    <GenericBtn
                                        primary={true}
                                        title={value['name'] || 'New Object'}
                                        onClick={() => {
                                            props.setOpenEditors([...props.openEditors, {
                                                title: scope.join('/'),
                                                value: value,
                                                setValue: openFile.setValue,
                                                scope: scope,
                                                uniqueId: openFile.uniqueId,
                                                isFile: false
                                            }]);
                                        }}
                                        readonly={readonly}
                                    />
                                </>
                            )}
                            {typeof value === 'boolean' && (
                                <GenericInput
                                    type='boolean'
                                    value={value}
                                    setValue={(e) => setValue(e)}
                                    readonly={readonly}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            );
        default:
            return null;
    }
};

interface RowRenderFieldProps {
    fieldKey: string;
    property: ObjectProperty;
    value: any | undefined | null;
    setValue: (value: any) => void;
    props: InternalProps;
    scope: (number | string)[];
    openFile: OpenFile;
    renderTitle?: boolean;
    showAdvanced?: boolean;
    readonly?: boolean;
}

const RowRenderFieldCog: React.FC<RowRenderFieldProps> = ({
    fieldKey,
    property,
    value,
    setValue,
    props,
    scope,
    openFile,
    renderTitle,
    showAdvanced,
    readonly
}) => {
    // Display a cog icon that opens a menu where the user can select 'default', 'null' or 'value', depending on the property
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLSpanElement>(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (option: string) => {
        switch (option) {
            case 'null':
                setValue(null);
                break;
            case 'value':
                setValue(getDefaultFilled(property));
                break;
            case 'default':
                setValue(undefined);
                break;
        }

        setIsOpen(false);
    };

    const hasDefaultOption = !isRequired(property);
    const isNullableOption = isNullable(property);

    const options = [];

    if (hasDefaultOption && value !== undefined) {
        options.push('default');
    }

    if (isNullableOption && value !== null) {
        options.push('null');
    }

    if (value === null || value === undefined) {
        options.push('value');
    }

    return (
        <span ref={ref} style={{ position: 'relative', display: 'inline-block' }}>
            <LiaCogSolid
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={handleToggle}
                color='white'
            />
            {isOpen && (
                <div className='vs-bg' style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1000, backgroundColor: 'white', border: '1px solid black' }}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            style={{ padding: '5px', cursor: 'pointer' }}
                            onClick={() => handleSelect(option)}
                            className='white-text vs-bg'
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </span>
    );
}

const RowRenderField: React.FC<RowRenderFieldProps> = ({
    fieldKey,
    property,
    value,
    setValue,
    props,
    scope,
    openFile,
    renderTitle,
    showAdvanced,
    readonly
}) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [showAllProperties, setShowAllProperties] = useState<boolean>(false);

    const internalSetValue = (newValue: any) => {
        console.log("Setting value:", newValue);
        setValue(newValue);
    }

    const loweredName = fieldKey.toLowerCase();

    if (renderTitle) {
        if (property.type === 'object') {
            const idName = `render-field-${loweredName}`;
            return (
                <div id={idName}>
                    <div style={{ padding: '10px', border: '1px solid #333', borderRadius: 5, marginBottom: 10 }}>
                        <p className='w-100' style={{ borderBottom: '1px solid #333' }}>
                            <span className='vs-bg wheat-text' style={{ float: 'left' }} >
                                {normalizeTitle(fieldKey)}{'  '}
                            </span>
                            <span className='vs-bg' style={{ float: 'left' }} >
                                {(!isRequired(property) || isNullable(property)) && <RowRenderFieldCog {...{ fieldKey, property: property, value, setValue, props, scope, openFile, showAdvanced, readonly }} />}
                            </span>
                            {' '}
                            <span className='vs-bg wheat-text' style={{ cursor: 'pointer' }} onClick={() => setCollapsed(!collapsed)}>
                                {collapsed ? '▼' : '▲'}
                            </span>
                            <span className='vs-bg white-text' style={{ cursor: 'pointer', float: 'right' }} onClick={() => setShowAllProperties(!showAllProperties)}>
                                {showAllProperties ? 'Hide Advanced' : 'Show Advanced'}
                            </span>
                            <br></br>
                        </p>

                        {!collapsed && (
                            <>
                                <RowRenderField
                                    fieldKey={fieldKey}
                                    property={property}
                                    value={value}
                                    setValue={internalSetValue}
                                    props={props}
                                    showAdvanced={showAllProperties}
                                    openFile={openFile}
                                    scope={scope}
                                    readonly={readonly}
                                />
                            </>
                        )}
                    </div>
                    <GuideHandler element={idName} />
                </div>
            );
        }

        const idName = `render-field-${loweredName}`;

        return (
            <Row className='bottom-margin-sm p-3'>
                <Row className='input-container' id={idName}>
                    <Col md={2} sm={12}>
                        <p className='w-100'>
                            <MyTooltip tooltipText={property.description}>
                                <span className='vs-bg wheat-text'>{normalizeTitle(fieldKey)} </span>
                                {(!isRequired(property) || isNullable(property)) && <RowRenderFieldCog {...{ fieldKey, property, value, setValue, props, scope, openFile, showAdvanced, readonly }} />}
                                {isRequired(property) && <span className='vs-bg red-text'> *</span>}
                            </MyTooltip>
                        </p>
                        <GuideHandler element={idName} />
                    </Col>
                    <Col md={10} sm={12} className='top-margin-sm'>
                        <RowRenderField
                            fieldKey={fieldKey}
                            property={property}
                            value={value}
                            setValue={internalSetValue}
                            props={props}
                            openFile={openFile}
                            scope={scope}
                            readonly={readonly}
                        />
                    </Col>
                </Row>
            </Row>
        );
    }



    if (!isRequired(property)) {
        if (isNullable(property)) {
            const selected = (typeof value === 'undefined') ? 'default' : (value === null ? 'null' : 'value');
            console.log("Selected:", selected, "Value:", value);
            return (
                <Row>
                    <Col className='top-margin-sm' md={12}>
                        {value !== null && value !== undefined &&
                            <RenderField
                                fieldKey={fieldKey}
                                property={property}
                                value={value}
                                setValue={internalSetValue}
                                props={props}
                                showAdvanced={showAdvanced}
                                openFile={openFile}
                                scope={scope}
                                readonly={readonly}
                            />}
                        {value === null && <GenericText title='<null>' />}
                        {value === undefined && <GenericText title={(typeof property['default-hint'] !== 'undefined') ? `${JSON.stringify(property['default-hint'])} (default)` : '<default>'} />}
                    </Col>
                </Row>
            );
        }
        else {
            return (
                <Row>
                    <Col md={12} className='top-margin-sm'>
                        {value !== undefined &&
                            <RenderField
                                fieldKey={fieldKey}
                                property={property}
                                value={value}
                                setValue={internalSetValue}
                                props={props}
                                showAdvanced={showAdvanced}
                                openFile={openFile}
                                scope={scope}
                                readonly={readonly}
                            />}
                        {value === undefined && <GenericText title={(typeof property['default-hint'] !== 'undefined') ? JSON.stringify(property['default-hint']) : '<default>'} />}
                    </Col>
                </Row>
            );
        }
    }
    else {
        return (
            <Row>
                <Col md={12}>
                    {
                        <RenderField
                            fieldKey={fieldKey}
                            property={property}
                            value={value}
                            setValue={internalSetValue}
                            props={props}
                            showAdvanced={showAdvanced}
                            openFile={openFile}
                            scope={scope}
                            readonly={readonly}
                        />
                    }
                </Col>
            </Row>
        );
    }
}

const InternalGraphicalEditor: React.FC<GraphicalEditorProps> = ({ value, setValue, props, openFile, readonly }) => {
    const [template, setTemplate] = useState<Template | null>(null);
    const previousTypeRef = useRef<string | null>(null);
    const [modTypes, setModTypes] = useState<string[]>([]);
    const [name, setName] = useState<string>('');

    useEffect(() => {
        modService.getModTemplateNames().then(data => {
            setModTypes(data);
        });
    }, []);

    if (modTypes.length === 0 && value["type"] === undefined) {
        value["type"] = "object";
    }

    if (!value) {
        value = {};
    }

    if (value["type"] === undefined) {
        value["type"] = modTypes[0];
    }

    useEffect(() => {
        console.log("Effect triggered. Current type:", value["type"], "Previous type:", previousTypeRef.current);

        if (value["type"] === previousTypeRef.current) {
            return;
        }

        previousTypeRef.current = value["type"];

        console.log("Fetching template...");

        modService.getModTemplate(value["type"]).then(data => {
            setTemplate(data);
        });
    }, [value["type"]]);

    useEffect(() => {
        if (value["name"]) {
            setName(value["name"]);
        }
    }, [value["name"]]);

    const handleSetNewName = () => {
        let aliases = value['aliases'] || [];
        let oldName = value['name'];
        if (!aliases.includes(oldName) && oldName !== name) {
            if (oldName !== '' && oldName !== undefined && oldName !== null) {
                aliases.push(oldName);
            }
        }

        setValue({
            ...value,
            name: name,
            aliases: aliases
        });

        openFile.title = name;
    }

    const handleSetValue = (fieldKey: string, newValue: any) => {
        console.log("Setting value [InternalGraphicalEditor]:", fieldKey, newValue);
        setValue({
            ...value,
            [fieldKey]: newValue
        });
    };

    let currentName = value['name'] || '';

    return (
        <>
            {/* Name input */}
            <GenericInput
                title="Name"
                type="text"
                value={name}
                setValue={(e) => setName(e)}
                titleClassNames='left-text'
                readonly={readonly}
            />
            {currentName !== name && (
                <GenericBtn
                    title="Confirm new name and add alias"
                    onClick={handleSetNewName}
                    readonly={readonly}
                    primary={true}
                />
            )}
            {/* Enum for selecting the type */}
            <GenericEnum
                title="Type"
                options={modTypes}
                value={value['type']}
                setValue={(e) => {
                    // Change the type, do not remove any existing values
                    setValue({
                        ...value,
                        type: e
                    });
                }}
                titleClassNames='left-text'
                formatOption={(option) => FormatEnumOption(option)}
                readonly={readonly}
            />
            {!template && <GenericText title="Loading..." />}
            {value && value['type'] && template && template.name === value['type'] && (
                <>
                    {Object.keys(template.variables).map((fieldKey, key) => {
                        let property = template.variables[fieldKey];
                        let aliases = property.aliases || [];
                        // Check if fieldKey exists in value, if not, check for aliases
                        // Default to using the fieldKey
                        let valueKey = fieldKey;
                        if (value[fieldKey] === undefined) {
                            valueKey = aliases.find((alias) => value[alias] !== undefined) || fieldKey;
                        } else {
                            valueKey = fieldKey;
                        }

                        return (
                            <div key={key}>
                                <RowRenderField
                                    fieldKey={valueKey}
                                    property={template.variables[fieldKey]}
                                    value={value[valueKey]}
                                    setValue={(newValue) => handleSetValue(valueKey, newValue)}
                                    props={props!}
                                    renderTitle={true}
                                    openFile={openFile}
                                    scope={[valueKey]}
                                    readonly={readonly}
                                />
                            </div>
                        )
                    }
                    )}
                </>
            )}
        </>
    );
};

export interface InternalProps {
    openEditors: OpenFile[];
    setOpenEditors: (value: OpenFile[]) => void;
}

export const GraphicalEditor: React.FC<GraphicalEditorProps> = ({ value, setValue, openFile, props, readonly }) => {
    // Scrollable container
    return (
        <>
            <div style={{ overflowY: 'scroll', height: '100%', padding: '10px', overflowX: 'hidden' }}>
                <InternalGraphicalEditor value={value} setValue={setValue} props={props} openFile={openFile} scope={[]} readonly={readonly} />
            </div>
        </>
    );
};