import React, { useCallback, useEffect, useState } from "react";
import { ModelViewer } from "./ModelViewer";
import "./ModelAnimationViewer.css"; // Import CSS for styling

interface ModelAnimationViewerProps {
    modelUrl: string;
}

export const ModelAnimationViewer: React.FC<ModelAnimationViewerProps> = ({ modelUrl }) => {
    const [animations, setAnimations] = useState<string[]>([]);
    const [currentAnimation, setCurrentAnimation] = useState<string | undefined>();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true); // Toggle panel visibility

    useEffect(() => {
        setAnimations([]);
        setCurrentAnimation(undefined);
    }, [modelUrl]);

    const handleAnimationsLoaded = useCallback((animationList: string[]) => {
        if (animations.length === 0) {
            console.log("Animations loaded:", animationList);
            setAnimations(animationList);
            if (animationList.length > 0) {
                // Default to a random animation
                setCurrentAnimation(animationList[Math.floor(Math.random() * animationList.length)]);
            }
        }
    }, [modelUrl, animations]);

    return (
        <div className="model-animation-viewer">
            {/* Model Viewer */}
            <ModelViewer
                modelUrl={modelUrl}
                onAnimationsLoaded={handleAnimationsLoaded}
                animationToPlay={currentAnimation}
                transitionDuration={1}
            />

            {/* Animation Selector Panel */}
            <div className={`animation-panel ${isPanelOpen ? "open" : "closed"}`}>
                <div className="animation-buttons">
                    {animations.map((name) => (
                        <button
                            key={name}
                            className={`animation-button ${
                                currentAnimation === name ? "active" : ""
                            }`}
                            onClick={() => {
                                console.log("Playing animation:", name);
                                setCurrentAnimation(name);
                            }}
                        >
                            {name}
                        </button>
                    ))}
                </div>
            </div>

            {/* Toggle Button */}
            <div
                className="toggle-button"
                onClick={() => setIsPanelOpen((prev) => !prev)}
            >
                {isPanelOpen ? "↓" : "↑"}
            </div>
        </div>
    );
};
