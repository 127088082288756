import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { modService } from "./components/ModService";

export enum Permission {
    None = 0 << 0,
    ReadResources = 1 << 0,
    WriteResources = 1 << 1,
    Deploy = 1 << 2,
    ReadMods = 1 << 5,
    WriteMods = 1 << 6,
    Login = 1 << 7,
    InviteUsers = 1 << 9
};

export interface Identity {
    userId: number;
    discordId: string;
    discordName: string;
    timeOfCreation: number;
    permissions: number; // Permission
    overrides: {[key: string]: number}; // Project -> Permission
}

export class Authorization {
    identity: Identity;

    constructor() {
        const jwt = Cookies.get('token');
        if (jwt) {
            this.identity = jwtDecode(jwt);
        }
        else {
            this.identity = {
                userId: 0,
                discordId: '0',
                discordName: 'Guest',
                timeOfCreation: 0,
                permissions: Permission.None,
                overrides: {}
            };
        }
    }

    public reload() {
        const jwt = Cookies.get('token');
        if (jwt) {
            this.identity = jwtDecode(jwt);
        }
        else {
            this.identity = {
                userId: 0,
                discordId: '0',
                discordName: 'Guest',
                timeOfCreation: 0,
                permissions: Permission.None,
                overrides: {}
            };
        }
    }

    public renew() {
        modService.refreshToken().then((jwt: string) => {
            Cookies.set('token', jwt);
            this.reload();
        });
    }

    public hasPermissionForProject(permission: Permission, project: string): boolean {
        if ((this.identity.permissions & permission) === permission) {
            return true;
        }
        
        const override = this.identity.overrides[project];

        if (override && (override & permission) === permission) {
            return true;
        }

        return false;
    }

    public hasPermission(permission: Permission): boolean {
        const project: string | undefined = Cookies.get('project');

        if (!project) {
            return false;
        }

        return this.hasPermissionForProject(permission, project);
    }

    public hasBasePermission(permission: Permission): boolean {
        return (this.identity.permissions & permission) === permission;
    }

    public getName(): string {
        return this.identity.discordName;
    }
}

export const authorization = new Authorization();

export function logout() {
    Cookies.remove('token');
    authorization.reload();
}

