import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './components.css';

interface GenericEnumProps {
    title?: string;
    options: string[];
    value: any;
    titleClassNames?: string;
    setValue: (value: any) => void;
    formatOption?: (option: string) => string;
    readonly?: boolean;
    rotate?: boolean;
}

export const GenericEnum: React.FC<GenericEnumProps> = ({ title, options, value, titleClassNames, setValue, formatOption, readonly, rotate }) => {
    const titleClassNamesFinal = titleClassNames ? titleClassNames : 'center-text';
    const isDisabled = readonly ? true : false;

    return (
        <Row>
            {title && (
            <Col md={3} className={titleClassNamesFinal}>
                <p className='wheat-text'>{title}</p>
            </Col>
            )}
            {!rotate && (
            <Col md={title ? 9 : 12}>
                <select className={"wheat-text vs-bg w-100 generic-input"}
                    disabled={isDisabled}
                    value={value} onChange={(e) => { setValue(e.target.value); }}>
                    {options.map((option, index) => {
                        return <option className='vs-bg generic-input wheat-text' key={index} value={option}>{formatOption ? formatOption(option) : option}</option>;
                    })}
                </select>
            </Col>)}
            {/* Button that rotate between the values */}
            {rotate && (
            <Col md={title ? 9 : 12} className='center-text'>
                <button className='generic-button w-100 vs-bg' onClick={() => {
                    const currentIndex = options.indexOf(value);
                    const nextIndex = currentIndex + 1 >= options.length ? 0 : currentIndex + 1;
                    setValue(options[nextIndex]);
                }}>{formatOption ? formatOption(value) : value}</button>
            </Col>)}
        </Row>
    );
};