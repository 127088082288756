import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import "./ImageDropdown.css";

interface ImageDropdownProps {
    items: { value: string; image: string; text: string }[];
    selectedValue: string;
    onSelect: (value: string) => void;
}

const ImageDropdown: React.FC<ImageDropdownProps> = ({ items, selectedValue, onSelect }) => {
  const [selected, setSelected] = useState<string>(selectedValue);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect(value); // Call the callback with the new selection
  };

  // Find the selected item based on the current value
  const selectedItem = items.find((item) => item.value === selected) || items[0];

  return (
    <Dropdown className="custom-dropdown">
      {/* Dropdown Toggle */}
      <Dropdown.Toggle variant="light" id="dropdown-custom-components" className="p-0 custom-dropdown-toggle">
        <img src={selectedItem.image} alt="Selected Icon" className="dropdown-icon" />
      </Dropdown.Toggle>

      {/* Dropdown Menu */}
      <Dropdown.Menu>
        {items.map((item) => (
          <Dropdown.Item
            key={item.value}
            as="button"
            onClick={() => handleSelect(item.value)}
            className="d-flex align-items-center"
          >
            <img src={item.image} alt={item.text} className="option-image" />
            <span className="ms-2">{item.text}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};


export default ImageDropdown;
