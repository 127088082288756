import { useParams } from 'react-router-dom';
import { ModelViewer } from './ModelViewer';
import { modService } from '../ModService';
import { WorldViewer } from './WorldViewer';
import { TerrainViewer } from './TerrainViewer';

export const TerrainEmbedded = () => {
    const  { "*": terrain } = useParams();

    if (!terrain) {
        return null;
    }

    return (
        <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
            <TerrainViewer terrain={terrain} />
        </div>
    );
}