import { FormatEnumOption } from "../Formatters";
import { GenericEnum } from "../GenericEnum";
import { InternalProps } from "../graphical/GraphicalEditor";
import { OpenFile } from "../OpenFile";
import { useState } from "react";
import { ItemView } from "./ItemView/ItemView";

export interface AssistedViewProps {
    value: any;
    setValue: (value: any) => void;
    props: InternalProps;
    openFile: OpenFile;
    scope: (number | string)[];
    readonly: boolean;
}
const viewTypes = [
    "none",
    "item"
];

export const AssistedView: React.FC<AssistedViewProps> = ({
    value, setValue, props, openFile, scope, readonly
}) => {
    const [viewType, setViewType] = useState<string | null>(value["view-type"] || "none");

    return (
        <div>
            <GenericEnum
                title="View Type"
                options={viewTypes}
                value={viewType}
                setValue={(e) => {
                    setViewType(e);
                    setValue({
                        ...value,
                        "view-type": e
                    });
                }}
                titleClassNames='left-text'
                formatOption={(option) => FormatEnumOption(option)}
                readonly={readonly}
            />

            {viewType === "item" && (
                <ItemView
                    value={value}
                    setValue={setValue}
                    props={props}
                    openFile={openFile}
                    scope={scope}
                    readonly={readonly}
                />
            )}
        </div>
    );
};
