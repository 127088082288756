import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './components.css';
import { FileUploader } from 'react-drag-drop-files';

interface GenericFileProps {
    title?: string;
    types: string[];
    titleClassNames?: string;
    onUpload: (file: File) => void;
}

export const GenericFile: React.FC<GenericFileProps> = ({ title, types, titleClassNames, onUpload }) => {
    const [file, setFile] = useState<File | null>(null);
    const titleClassNamesFinal = titleClassNames ? titleClassNames : 'center-text';

    const handleFile = (file: File) => {
        console.log('file', file);

        setFile(file);
        onUpload(file);
    }

    return (
        <Row className='pt-2 pb-2'>
            {title && title.length > 0 &&
            <Col md={3} className={titleClassNamesFinal}>
                <p className='wheat-text'>{title}</p>
            </Col>
            }
            <Col md={title && title.length > 0 ? 9 : 12}>
                <div className='w-100'>
                    <FileUploader
                        handleChange={handleFile}
                        types={types}
                        multiple={false}
                    />
                </div>
            </Col>
        </Row>
    );
};