import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ImportantNoticeModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const isNoticeDismissed = localStorage.getItem('noticeDismissed');
        if (!isNoticeDismissed) {
            setShowModal(true); // Show modal if it hasn't been dismissed
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('noticeDismissed', 'true');
        setShowModal(false);
    };

    return (
        <Modal show={showModal} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Important Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>
                    <strong>LEGOⓇ is a trademark of The LEGO Group.</strong>
                </h3>
                <p>
                    Nejlika is a fan-made modding platform for LEGOⓇ Universe. It is not affiliated with, supported, or endorsed by The LEGO Group.
                </p>
                <p>
                    To develop, play, and share mods, you must provide your own copy of LEGOⓇ Universe. <strong>This website does not distribute the game.</strong>
                </p>
                <p>
                    <strong>Do not download the client from websites that advertise it!</strong> There are malicious actors that distribute the client and their integrity cannot be guaranteed, and their activites should not be encouraged.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    I Understand
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImportantNoticeModal;
