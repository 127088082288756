import React, { useEffect } from 'react';
import logo from './logo.svg';
import {
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
} from 'react-bootstrap';
import './components.css';
import { modService } from './ModService';
import { useState } from 'react';
import { CreateModPackCard } from './CreateModPackCard';
import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { CreateModCard } from './CreateModCard';
import { GenericText } from './GenericText';
import { CreateProjectCard } from './CreateProjectCard';
import { HotKeyHint } from './HotKeyHint';
import { LogMessage, Severity } from './Logger';
import { authorization, logout, Permission } from '../Authorization';
import AccountModal from './AccountModal/AccountModal';
import IntroductionModal from './IntroductionModal/IntroductionModal';
import { InviteUserCard } from './InviteUserCard';
import { GuideHandlers } from './Guide/GuideHandler';
import { EditorMode } from './EditorMode';
import { InterfaceUserSettingsProps } from './InterfaceUserSettings';

type NavigationProps = InterfaceUserSettingsProps;

export const Navigation: React.FC<NavigationProps> = ({
    userSettings,
    dispatchUserSettings
}) => {
    const [showCreateModPack, setShowCreateModPack] = useState<boolean>(false);
    const [showCreateMod, setShowCreateMod] = useState<boolean>(false);
    const [showCreateProject, setShowCreateProject] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<string>(Cookies.get('project') || 'default');
    const [allProjects, setAllProjects] = useState<string[]>([]);
    const [databaseIsRunning, setDatabaseIsRunning] = useState<boolean>(false);
    const [gameServerIsRunning, setGameServerIsRunning] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
    const [showIntroductoryModal, setShowIntroductoryModal] = useState<boolean>(false);
    const [hasPersonalProject, setHasPersonalProject] = useState<boolean>(false);
    const [showInviteUser, setShowInviteUser] = useState<boolean>(false);

    const onDeploy = () => {
        LogMessage("Deployment started, this might take awhile", Severity.INFO);
        modService.save().then(data => {
            if (data["error"]) {
                LogMessage("Error deploying", Severity.ERROR);
                LogMessage(data["error"], Severity.ERROR);
            }
            else {
                LogMessage("Deployed successfully", Severity.INFO);
            }
        });
    }

    const onLoad = () => {
        window.location.href = '/load';
    }

    const onCreateModPack = () => {
        setShowCreateModPack(true);
    }

    const onFinishCreateModPack = (value: { name: string, description: string, author: string, version: string }) => {
        setShowCreateModPack(false);

        modService.createPack(value.name, value.description, value.author, value.version).then(data => {
            if (!data) {
                LogMessage("Error creating mod pack", Severity.ERROR);
                return;
            }

            LogMessage("Created mod pack", Severity.INFO);
        });
    }

    const onFinishCreateProject = (value: string) => {
        setSelectedProject(value);
        setShowCreateProject(false);
    }

    const onSaveModPacks = () => {
        modService.saveModPacks().then(data => {
            if (!data) {
                LogMessage("Error saving mod packs", Severity.ERROR);
            }
            else {
                LogMessage("Saved mod packs", Severity.INFO);
            }
        });
    }

    const onCreateMod = () => {
        setShowCreateMod(true);
    }

    const onCreateProject = () => {
        setShowCreateProject(true);
    }

    const onFinishCreateMod = (value: any) => {

    }

    const updateProjectStatus = () => {
        modService.getProject(selectedProject).then((data) => {
            if (data == null) {
                return;
            }

            setDatabaseIsRunning(data.database);
            setGameServerIsRunning(data.gameServer);
            setIsPublic(data.isPublic);
        });
    }

    const fetchProjects = () => {
        modService.getProjects().then(data => {
            setAllProjects(data);

            const identity = authorization.getName();

            if (identity !== 'Guest') {
                if (!data.includes(identity)) {
                    setHasPersonalProject(false);
                }
                else {
                    setHasPersonalProject(true);
                }
            }
        });
    }

    // Fetch projects
    useEffect(() => {
        modService.getProjects().then(data => {
            setAllProjects(data);

            const identity = authorization.getName();

            if (identity !== 'Guest') {
                if (!data.includes(identity)) {
                    setShowIntroductoryModal(true);
                    setHasPersonalProject(false);
                }
                else {
                    setHasPersonalProject(true);
                }
            }
        });

        // Fetch projects every 5 seconds
        const interval = setInterval(() => {
            fetchProjects();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    // Fetch project status
    useEffect(() => {
        updateProjectStatus();

        // Fetch project status every 5 seconds
        const interval = setInterval(() => {
            updateProjectStatus();
        }, 5000);

        return () => clearInterval(interval);
    }, [selectedProject]);

    const startProject = () => {
        LogMessage("Starting project, please wait", Severity.INFO);

        modService.startProject(selectedProject).then(() => {
            updateProjectStatus();

            LogMessage("Started project", Severity.INFO);
        });
    }

    const stopProject = () => {
        LogMessage("Stopping project, please wait", Severity.INFO);

        modService.stopProject(selectedProject).then(() => {
            updateProjectStatus();

            LogMessage("Stopped project", Severity.INFO);
        });
    }

    const onProjectSelected = (project: string) => {
        setSelectedProject(project);
        Cookies.set('project', project, { expires: 180, path: '' });
        fetchProjects();

        LogMessage("Selected project: " + project, Severity.INFO);
    }

    const onSetPublic = (state: boolean) => {
        modService.setProjectPublic(selectedProject, state).then((success) => {
            if (success) {
                setIsPublic(state);
                LogMessage("Set project to " + (state ? "public" : "private"), Severity.INFO);
            }
            else {
                LogMessage("Failed to set project to " + (state ? "public" : "private"), Severity.ERROR);
            }
        });
    }

    const onShowSource = () => {
        dispatchUserSettings({ type: "SET_GUI_ENABLED", payload: 'source' });
    }

    const onShowGui = () => {
        dispatchUserSettings({ type: "SET_GUI_ENABLED", payload: 'graphical' });
    }

    const onShowAssisted = () => {
        dispatchUserSettings({ type: "SET_GUI_ENABLED", payload: 'assisted' });
    }

    const onShowExplorer = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showExplorer' });
    }

    const onShowLogger = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showLogger' });
    }

    const onShowUnity = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showUnity' });
    }

    const onShowEditor = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showEditor' });
    }

    const onShowGuide = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showGuide' });
    }

    const onShowResources = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showResources' });
    }

    const onShowDatabase = () => {
        dispatchUserSettings({ type: "TOGGLE_SHOW", key: 'showDatabase' });
    }

    const hotKeys = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            onSaveModPacks();
        }

        if (event.altKey && event.key === 'n') {
            event.preventDefault();
            onCreateMod();
        }

        if (event.ctrlKey && event.key === 'p') {
            event.preventDefault();
            onCreateModPack();
        }

        if (event.ctrlKey && event.key === 'd') {
            event.preventDefault();
            onDeploy();
        }

        if (event.altKey && event.key === 'u') {
            event.preventDefault();
            if (userSettings.guiEnabled !== 'source') {
                onShowSource();
            }
            else {
                onShowGui();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', hotKeys);

        return () => {
            document.removeEventListener('keydown', hotKeys);
        }
    }, []);

    const onLogout = () => {
        // Remove cookies
        Cookies.set('token', '', { expires: 3600, path: '/', domain: 'nejlika.org', sameSite: 'none', secure: true });

        setTimeout(() => {
            // Redirect to home page
            window.location.href = '/';
        }, 1000);
    }

    const onDownloadAccountData = () => {
        modService.downloadAccountData().then((data) => {
            if (data == null) {
                LogMessage("Failed to download account data", Severity.ERROR);
                return;
            }

            const jsonData = JSON.stringify(data, null, 2); // Convert data to JSON string
            const url = window.URL.createObjectURL(new Blob([jsonData], { type: 'application/json' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'account_data.json');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up the DOM

            LogMessage("Downloaded account data", Severity.INFO);
        });
    }

    const onDeleteAccount = () => {
        modService.deleteAccount().then((success) => {
            if (success) {
                onLogout();
                LogMessage("Deleted account", Severity.INFO);
            }
            else {
                LogMessage("Failed to delete account", Severity.ERROR);
            }
        });
    }

    const onPlay = () => {
        window.location.href = '/launcher';
    }

    const onCreatePersonalProject = () => {
        setShowIntroductoryModal(true);
    };

    const onSuccessfulCreatePersonalProject = () => {
        const identity = authorization.getName();

        if (identity !== 'Guest') {
            setHasPersonalProject(true);
            onProjectSelected(identity);

            authorization.renew();

            // Refresh the page
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }

        setShowIntroductoryModal(false);
    }

    const username = authorization.getName();

    const canDeploy = authorization.hasPermissionForProject(Permission.Deploy, selectedProject);
    const canWriteMods = authorization.hasPermissionForProject(Permission.WriteMods, selectedProject);
    const canCreateProject = authorization.hasBasePermission(Permission.WriteMods);
    const canReadMods = authorization.hasPermissionForProject(Permission.ReadMods, selectedProject);
    const canInviteUsers = authorization.hasPermissionForProject(Permission.InviteUsers, selectedProject);

    return (
        <Navbar id='my-navbar' expand="lg" className='vs-bg'>
            {/* If we are on the '/' page, redirect to #, otherwise redirect to '/' */}
            {window.location.pathname === '/' ? (
                <Navbar.Brand href="#" className='logo-text wheat-text'>nejlika</Navbar.Brand>
            ) : (
                <Navbar.Brand href="/" className='logo-text wheat-text'>nejlika</Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {/* The rest should be on the right of the screen*/}
            <Navbar.Collapse id="basic-navbar-nav">
                <div className=""></div>
                <Nav className="mr-auto left">
                    <NavDropdown title="Project" id="project-dropdown" className='vs-bg white-text'>
                        {allProjects.map((project) => (
                            <NavDropdown.Item className='navigation-item'
                                key={project}
                                onClick={() => onProjectSelected(project)}
                                disabled={!authorization.hasPermissionForProject(Permission.ReadMods, project)}
                            >
                                {project === selectedProject ? (
                                    <strong>{project}</strong>
                                ) : (
                                    project
                                )}
                            </NavDropdown.Item>
                        ))}
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="create-personal-button" className='navigation-item' onClick={onCreatePersonalProject} disabled={hasPersonalProject}>Create Personal Project</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="create-project-button" className='navigation-item' onClick={onCreateProject} disabled={!canCreateProject}>New Project</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="File" id="file-dropdown" className='vs-bg white-text'>
                        <NavDropdown.Item id="new-mod-button" className='navigation-item' onClick={onCreateMod} disabled={!canWriteMods}>New Mod <HotKeyHint keys={['Alt', 'N']} /></NavDropdown.Item>
                        {/* Seperator */}
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="new-package-button" className='navigation-item' onClick={onCreateModPack} disabled={!canWriteMods}>New Package <HotKeyHint keys={['Ctrl', 'P']} /></NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="save-all-button" className='navigation-item' onClick={onSaveModPacks} disabled={!canWriteMods}>Save All <HotKeyHint keys={['Ctrl', 'S']} /></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Deployment" id="deployment-dropdown" className='vs-bg white-text'>
                        {(databaseIsRunning && gameServerIsRunning) ? (
                            <NavDropdown.Item id="stop-servers-button" className='navigation-item' onClick={stopProject} disabled={!canDeploy}>Stop Servers</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item id="start-servers-button" className='navigation-item' onClick={startProject} disabled={!canDeploy}>Start Servers</NavDropdown.Item>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="change-public-button" className='navigation-item' onClick={() => onSetPublic(!isPublic)} disabled={!canDeploy}>{isPublic ? "Make Private" : "Make Public"}</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="deploy-button" className='navigation-item' onClick={onDeploy} disabled={!canDeploy}>Deploy Mods <HotKeyHint keys={['Ctrl', 'D']} /></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Play" id="play-dropdown" className='vs-bg white-text'>
                        <NavDropdown.Item id="download-launcher-button" className='navigation-item' onClick={onPlay}>Download Launcher</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item id="invite-user-button" className='navigation-item' onClick={() => setShowInviteUser(true)} disabled={!canInviteUsers}>Invite User</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="View" id="view-dropdown" className='vs-bg white-text'>
                        {userSettings.guiEnabled != 'source' && (
                            <NavDropdown.Item className='navigation-item' onClick={onShowSource}>Show Source <HotKeyHint keys={['Alt', 'U']} /></NavDropdown.Item>
                        )}
                        {userSettings.guiEnabled != 'graphical' && (
                            <NavDropdown.Item className='navigation-item' onClick={onShowGui}>Show Graphical <HotKeyHint keys={['Alt', 'U']} /></NavDropdown.Item>
                        )}
                        {userSettings.guiEnabled != 'assisted' && (
                            <NavDropdown.Item className='navigation-item' onClick={onShowAssisted}>Show Assisted Editor</NavDropdown.Item>
                        )}
                        {userSettings.showEditor ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowEditor}>Hide Editor</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowEditor}>Show Editor</NavDropdown.Item>
                        )}
                        {userSettings.showExplorer ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowExplorer}>Hide Explorer</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowExplorer}>Show Explorer</NavDropdown.Item>
                        )}
                        {userSettings.showLogger ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowLogger}>Hide Logger</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowLogger}>Show Logger</NavDropdown.Item>
                        )}
                        {userSettings.showGuide ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowGuide}>Hide Guide</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowGuide}>Show Guide</NavDropdown.Item>
                        )}
                        {userSettings.showResources ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowResources}>Hide Resources</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowResources}>Show Resources</NavDropdown.Item>
                        )}
                        {userSettings.showDatabase ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowDatabase}>Hide Database</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowDatabase}>Show Database</NavDropdown.Item>
                        )}
                    </NavDropdown>
                    {/*
                    <Nav.Link href="#link" className='wheat-text'>Help</Nav.Link>
                    <Nav.Link href="#link" className='wheat-text'>About</Nav.Link>
                    */}
                </Nav>
                <Nav className="ms-auto right">
                    <NavDropdown title={`Hello ${username}!`} id="account-dropdown" className='user-nav-dropdown vs-bg white-text'>
                        <NavDropdown.Item className='navigation-item' onClick={() => setShowAccountModal(true)}>Settings</NavDropdown.Item>
                        <NavDropdown.Item className='navigation-item' onClick={onLogout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>

            {showCreateModPack &&
                <CreateModPackCard value={{}} save={(value) => {
                    onFinishCreateModPack(value);
                }} onClose={() => {
                    setShowCreateModPack(false);
                }} />
            }
            {showCreateMod &&
                <CreateModCard save={(value) => {
                    onFinishCreateMod(value);
                    setShowCreateMod(false);
                }} onClose={() => {
                    setShowCreateMod(false);
                }} />
            }
            {showCreateProject &&
                <CreateProjectCard onSave={(value) => {
                    onFinishCreateProject(value);
                }} onClose={() => {
                    setShowCreateProject(false);
                }} />
            }
            {showAccountModal &&
                <AccountModal
                    show={showAccountModal}
                    onHide={() => setShowAccountModal(false)}
                    onLogout={onLogout}
                    onDeleteAccount={onDeleteAccount}
                    onDownloadData={onDownloadAccountData}
                />
            }
            {showIntroductoryModal &&
                <IntroductionModal
                    show={showIntroductoryModal}
                    onHide={() => setShowIntroductoryModal(false)}
                    onCreateProject={() => onSuccessfulCreatePersonalProject()}
                />
            }
            {showInviteUser &&
                <InviteUserCard onClose={() => setShowInviteUser(false)} />
            }
            <GuideHandlers elements={[
                'project-dropdown',
                'file-dropdown',
                'deployment-dropdown',
                'play-dropdown',
                'view-dropdown',
                'account-dropdown',
                'create-personal-button',
                'create-project-button',
                'new-mod-button',
                'new-package-button',
                'save-all-button',
                'stop-servers-button',
                'start-servers-button',
                'change-public-button',
                'deploy-button',
                'download-launcher-button',
                'invite-user-button'
            ]} />
        </Navbar>
    );
};
