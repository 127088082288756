import React from 'react';

export const anchor = {
    width: 20,
    height: 20,
    background: 'white',
}

export const leftAnchor = { 
    left: 0,
    width: "fit-content",
    height: 30,
    borderRadius: 0,
    color: "white",
    background: '#880088',
    padding: 3,
    margin: 0,
    border: "2px solid rgb(192, 192, 192)",
    // Purple
    borderLeft: "2px solid #FF00FF",
    top: 20
};

export const rightAnchor = {
    right: 0,
    width: 110,
    height: 30,
    borderRadius: 0,
    color: "wheat",
    background: '#008800',
    backgroundColor: "#008800",
    padding: 3,
    margin: 0,
    border: "2px solid rgb(192, 192, 192)",
    // Green
    borderRight: "2px solid #00FF00",
    fontFamily: "monospace",
    fontSize: "0.6em",
    alignText: "center",
    top: 20
}