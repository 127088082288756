import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './components.css';

interface GenericIconProps {
    title?: string;
    url: string;
    alt: string;
    width?: number;
    height?: number;
}

export const GenericIcon: React.FC<GenericIconProps> = ({ title, url, alt, width, height}) => {
    width = width || 50;
    height = height || 50;
    
    return (
        <Row>
            {title && (
            <Col md={3}>
                <p className='wheat-text'>{title}</p>
            </Col>
            )}
            <Col md={title ? 9 : 12}>
                <div className='generic-input w-100' >
                    <img height={height} width={width} src={url} alt={alt} />
                </div>
            </Col>
        </Row>
    );
};