import React, { useState, useEffect, useRef } from "react";
import { HighlightedElements, HighlightListeners } from "./Guide";

interface GuideHandlerProps {
    element: string;
}

export const GuideHandler: React.FC<GuideHandlerProps> = ({element}) => {
    const [highlighted, setHighlighted] = useState<boolean>(false);

    useEffect(() => {
        const listener = (value: boolean) => {
            console.log("Highlighting " + element + " " + value);
            setHighlighted(value);
        }

        listener(HighlightedElements.includes(element));

        HighlightListeners[element] = listener;

        return () => {
            delete HighlightListeners[element];
        }
    }, [element]);

    useEffect(() => {
        const jsx = document.getElementById(element);

        if (!jsx) {
            return;
        }

        if (highlighted) {
            jsx.classList.add("slow-flash-border");
        } else {
            jsx.classList.remove("slow-flash-border");
        }
    }, [highlighted]);

    useEffect(() => {
        if (!highlighted) {
            return;
        }

        const interval = setInterval(() => {
            const jsx = document.getElementById(element);

            if (!jsx) {
                return;
            }

            jsx.classList.add("slow-flash-border");
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [highlighted, element]);

    return null;
}

interface GuideHandlersProps {
    elements: string[];
}

export const GuideHandlers: React.FC<GuideHandlersProps> = ({elements}) => {
    return (
        <>
            {elements.map((element, index) => (
                <GuideHandler key={index} element={element} />
            ))}
        </>
    );
}
