import React, { useEffect, useState } from 'react';
import './components.css';

interface GenericBtnProps {
    title: string;
    onClick: () => void;
    readonly?: boolean;
    id?: string;
    danger?: boolean;
    primary?: boolean;
    secondary?: boolean;
}

export const GenericBtn: React.FC<GenericBtnProps> = ({ title, onClick, readonly, id, danger, primary, secondary }) => {
    const isDisabled = readonly ? true : false;

    return (  
        <button id={id} className={
            `text-align-left generic-button w-100 vs-bg 
            ${danger ? 'danger-button' : ''} 
            ${primary ? 'primary-button' : ''} 
            ${secondary ? 'secondary-button' : ''}`
        } onClick={onClick} disabled={isDisabled}>{title}</button>
    );
};