import React, { useState, useEffect, useRef } from "react";

export enum Severity {
    INFO = "log-info",
    WARNING = "log-warning",
    ERROR = "log-error",
    DEBUG = "log-debug"
}

interface LogLine {
    msg: string;
    time: string; // HH:MM:SS
    severity: Severity;
}

let logs: LogLine[] = [];

let onLogUpdate: (() => void) | null = null;

export const LogMessage = (msg: string, severity: Severity) => {
    const now = new Date();
    logs.push({
        msg: msg,
        time: now.toTimeString().split(" ")[0],
        severity: severity
    });

    // Trigger a re-render in the Logger component
    if (onLogUpdate) {
        onLogUpdate();
    }
}

interface LoggerProps {}

export const Logger: React.FC<LoggerProps> = () => {
    const [logLines, setLogLines] = useState<LogLine[]>(logs);
    const [showScrollMessage, setShowScrollMessage] = useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Function to update the state when a new log is added
        onLogUpdate = () => {
            setLogLines([...logs]);  // Spread operator to ensure a new array is created, which triggers a re-render

            if (scrollContainerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
                const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;

                if (isAtBottom) {
                    // Auto-scroll to the bottom
                    scrollContainerRef.current.scrollTop = scrollHeight + 50;
                    setShowScrollMessage(false);
                } else {
                    // Show "Scroll down" message if not at the bottom
                    setShowScrollMessage(true);
                }
            }
        };

        // Clean up the event when the component unmounts
        return () => {
            onLogUpdate = null;
        };
    }, []);

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight;

            // Hide "Scroll down" message if user scrolls to the bottom
            if (isAtBottom) {
                setShowScrollMessage(false);
            }
        }
    };

    return (
        <div className="logger vs-bg">
            <div className="scrollable-h-100" ref={scrollContainerRef} onScroll={handleScroll}>
                <table className="log-container">
                    <tbody>
                        {logLines.map((log, i) => (
                            <tr key={i} className={`log-line vs-bg`}>
                                <td className={`log-msg ${log.severity}`}>{log.msg}</td>
                                <td className="log-time">{log.time}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showScrollMessage && (
                <div className="scroll-message">Scroll down for new log messages</div>
            )}
        </div>
    );
}