export const ComponentsList = [
    "ControllablePhysicsComponent",
    "RenderComponent",
    "SimplePhysicsComponent",
    "CharacterComponent",
    "ScriptComponent",
    "BouncerComponent",
    "DestructibleComponent",
    "GhostComponent",
    "SkillComponent",
    "SpawnerComponent",
    "ItemComponent",
    "RebuildComponent",
    "RebuildStartComponent",
    "RebuildActivatorComponent",
    "IconOnlyComponent",
    "VendorComponent",
    "InventoryComponent",
    "ProjectilePhysicsComponent",
    "ShootingGalleryComponent",
    "RigidBodyPhantomPhysicsComponent",
    "DropEffectComponent",
    "ChestComponent",
    "CollectibleComponent",
    "BlueprintComponent",
    "MovingPlatformComponent",
    "PetComponent",
    "PlatformBoundaryComponent",
    "ModuleComponent",
    "ArcadeComponent",
    "VehiclePhysicsComponent",
    "MovementAIComponent",
    "ExhibitComponent",
    "OverheadIconComponent",
    "PetControlComponent",
    "MinifigComponent",
    "PropertyComponent",
    "PetCreatorComponent",
    "ModelBuilderComponent",
    "ScriptedActivityComponent",
    "PhantomPhysicsComponent",
    "SpringpadComponent",
    "B3BehaviorsComponent",
    "PropertyEntranceComponent",
    "FXComponent",
    "PropertyManagementComponent",
    "SecondVehiclePhysicsComponent",
    "PhysicsSystemComponent",
    "QuickBuildComponent",
    "SwitchComponent",
    "MinigameComponent",
    "ChanglingComponent",
    "ChoiceBuildComponent",
    "PackageComponent",
    "SoundRepeaterComponent",
    "SoundAmbient2DComponent",
    "SoundAmbient3DComponent",
    "PreconditionComponent",
    "PlayerFlagsComponent",
    "CustomBuildAssemblyComponent",
    "BaseCombatAIComponent",
    "ModuleAssemblyComponent",
    "ShowcaseModelHandlerComponent",
    "RacingModuleComponent",
    "GenericActivatorComponent",
    "PropertyVendorComponent",
    "HFLightDirectionGadgetComponent",
    "RocketLaunchComponent",
    "RocketLandingComponent",
    "TriggerComponent",
    "DroppedLootComponent",
    "RacingControlComponent",
    "FactionTriggerComponent",
    "MissionNPCComponent",
    "RacingStatsComponent",
    "LUPExhibitComponent",
    "BBBComponent",
    "SoundTriggerComponent",
    "ProximityMonitorComponent",
    "RacingSoundTriggerComponent",
    "ChatComponent",
    "FriendsListComponent",
    "GuildComponent",
    "LocalSystemComponent",
    "MissionComponent",
    "MutableModelBehaviorsComponent",
    "PathfindingControlComponent",
    "PetTamingControlComponent",
    "PropertyEditorComponent",
    "SkinnedRenderComponent",
    "SlashCommandComponent",
    "StatusEffectComponent",
    "TeamsComponent",
    "TextEffectComponent",
    "TradeComponent",
    "UserControlComponent",
    "IgnoreListComponent",
    "LUPLaunchpadComponent",
    "BuffComponent",
    "DonationVendorComponent",
    "CombatMediatorComponent",
    "Component107",
    "Possesable"
];

export const ModTypes = [
    "file",
    "directory",
    "object",
    "component",
    "icon",
    "mission-task",
    "mission",
    "precondition",
    "render-component",
    "loot-matrix",
    "loot-table",
    "effect",
    "behavior",
    "skill",
    "speed-chat",
    "emote",
    "level-object",
    "collection"
]

export const ObjectTemplates: {[key: string]: { description: string, components: {[key: string]: {}} }} = {
    "Empty": {
        "description": "An object with no components",
        "components": {},
    },
    "Item": {
        "description": "An item for the player's inventory",
        "components": {
            "item": {
                "type": "component",
                "component": "ItemComponent",
                "values": {
                    "rarity": 1,
                    "itemType": 20,
                    "stackSize": 1,
                    "inLootTable": true,
                    "isUnique": false,
                    "isBOP": true
                }
            },
            "render": {
                "type": "render-component",
                "values": {
                    "shader-id": 1,
                }
            }
        }
    }
}