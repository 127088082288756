
import { modService } from "../ModService";

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Terrain, TerrainChunk } from "./Terrain";
import {
    Engine,
    Scene,
    SceneLoader,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    AnimationGroup,
    Color4,
    Quaternion,
    TransformNode,
    BoundingInfo,
    ActionManager,
    ExecuteCodeAction,
    AbstractMesh,
    UniversalCamera,
    Mesh,
    DirectionalLight,
    Color3,
    Matrix,
    FreeCameraMouseInput,
    InstancedMesh,
    AssetContainer,
    MeshBuilder,
    Texture,
    ShaderMaterial
} from "@babylonjs/core";

interface TerrainLoaderProps {
    scene: Scene;
    terrain: Terrain;
}

export const TerrainLoader: React.FC<TerrainLoaderProps> = ({ scene, terrain }) => {
    const [terrainNode, setTerrainNode] = useState<TransformNode>();

    const createTerrainChunk = useCallback((chunk: TerrainChunk, scene: Scene) => {
        const { path, scale, min, max, translation, width, height } = chunk.heightmap;

        // Create the ground mesh
        const terrainChunk = MeshBuilder.CreateGround("terrainChunk", {
            width: width,
            height: height,
            subdivisions: width - 1, // Use heightmap resolution
        }, scene);

        // Load the heightmap as a texture
        const heightMapTexture = new Texture(path, scene);

        // Create a custom shader material
        const terrainMaterial = new ShaderMaterial("terrainShader", scene, {
            vertex: "/shaders/terrain",
            fragment: "/shaders/terrain",
        }, {
            attributes: ["position", "uv"],
            uniforms: ["world", "worldView", "worldViewProjection", "heightMap", "scaleMin", "scaleMax"],
        });

        // Set the shader parameters
        terrainMaterial.setTexture("heightMap", heightMapTexture);
        terrainMaterial.setFloat("scaleMin", min);
        terrainMaterial.setFloat("scaleMax", max);
        terrainChunk.material = terrainMaterial;

        // Position the chunk based on its translation
        terrainChunk.setPositionWithLocalVector(new Vector3(translation[1], 0, translation[0]));
        terrainChunk.scaling.set(scale, 1, scale);
        terrainChunk.rotation.y = Math.PI;

        return terrainChunk;
    }, [scene]);

    const createTerrain = useCallback((chunk: Terrain) => {
        if (!scene) return;

        const centerX = 204.8 / 2.0;
        const centerY = 204.8 / 2.0;

        // Create a root node for the terrain
        const terrainRoot = new TransformNode("terrainRoot", scene);

        for (let terrainChunk of chunk.chunks) {
            const chunk = createTerrainChunk(terrainChunk, scene);

            // Attach the chunk to the root node
            chunk.parent = terrainRoot;
        }

        terrainRoot.position.set(centerX, 0, centerY);
        terrainRoot.scaling.set(-1, 1, 1);
        terrainRoot.rotation.y = Math.PI / 2;
    }, [scene, createTerrainChunk]);

    useEffect(() => {
        if (terrain && scene) {
            createTerrain(terrain);
        }
    }, [terrain, scene, createTerrain]);

    return null;
};
