import React, { Fragment, useCallback, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { modService } from "../ModService";
import Cookies from "js-cookie";

interface UnityPlayerProps {
    reload: boolean;
    setReload: (reload: boolean) => void;
    mode: string;
    model?: string;
    zone?: string;
    setup?: any;
    height?: number | string;
    width?: number | string;
    project?: string;
}

export const UnityPlayer: React.FC<UnityPlayerProps> = ({ mode, model, zone, setup, height, width, reload, setReload, project }) => {
    const { unityProvider, isLoaded, sendMessage, addEventListener, removeEventListener } = useUnityContext({
        loaderUrl: "/dandelion/Dandelion.loader.js",
        dataUrl: "/dandelion/Dandelion.data/webgl.data",
        frameworkUrl: "/dandelion/Dandelion.framework.js/build.framework.js",
        codeUrl: "/dandelion/Dandelion.wasm/build.wasm",
    });
    
    let message: (method: string, message: string) => void;
    let messageVoid: (method: string) => void;

    message = (method, message) => {
        console.log("UnityPlayer: " + method + "(" + message + ")");
        sendMessage("ApiMessageHandler", method, message);
    };
    messageVoid = (method) => {
        console.log("UnityPlayer: " + method);
        sendMessage("ApiMessageHandler", method);
    };

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        message("SetProject", project ?? Cookies.get("project") ?? "default");
        message("SetHost", modService.getEndpoint());
        message("SetMode", mode);
        if (mode === "model") {
            if (model) {
                message("SetModel", model);
            }
            else {
                console.log("UnityPlayer: model is undefined");
            }
        } else if (mode === "zone") {
            if (zone) {
                message("SetZone", zone);
            }
            else {
                console.log("UnityPlayer: zone is undefined");
            }
        } else if (mode === "setup") {
            if (setup) {
                message("SetSetup", JSON.stringify(setup));
            }
            else {
                console.log("UnityPlayer: setup is undefined");
            }
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        message("SetMode", mode);
        if (mode === "model") {
            if (model) {
                message("SetModel", model);
            }
            else {
                console.log("UnityPlayer: model is undefined");
            }
        } else if (mode === "zone") {
            if (zone) {
                message("SetZone", zone);
            }
            else {
                console.log("UnityPlayer: zone is undefined");
            }
        } else if (mode === "setup") {
            if (setup) {
                message("SetSetup", JSON.stringify(setup));
            }
            else {
                console.log("UnityPlayer: setup is undefined");
            }
        }
    }, [mode, model, zone]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        if (!reload) {
            return;
        }

        messageVoid("ReloadZone");
        setReload(false);
    }, [reload]);

    return (
        <div id='main' className="row">
            <div className="unity-player">
                <div className="unity-player">
                    <Unity
                        className="unity-player"
                        unityProvider={unityProvider}
                        style={{ width: width ?? "100%", height: height ?? "100%" }}
                    />
                </div>
            </div>
        </div>
    );
};