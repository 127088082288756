import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            {/* Content Section */}
            <div id="content" className="content-section py-5">
                <Container style={{ backgroundColor: 'white', padding: '20px' }}>
                    <h1>Cookie and Privacy Policy</h1>
                    <p><strong>Last Updated:</strong> 2024-09-28</p>

                    <h2>0. Welcome</h2>
                    <p>
                        <p>
                            Hi, I'm Wincent! This is the Cookie and Privacy Policy of my website, Nejlika. This document exists
                            to create transparency about how your data is handled when you use this website. I know,
                            legal is boring, but I didn't want to host a public website without these formalities.
                        </p>
                        <br />
                        <p><strong>We</strong> will henceforth refers to the operator(s) of this website.</p>
                        <p><strong>You</strong> will henceforth refers to the user of this website.</p>
                    </p>
                    <h2>1. Overview</h2>
                    <p>
                        We respect your privacy and are committed to protecting your personal data.
                        This policy explains how we use cookies and handle personal information collected through our website.
                        By using this site, you agree to the practices described here.
                    </p>

                    <h2>2. Cookies</h2>
                    <p>
                        Our website uses only functional cookies that are necessary for the site to operate properly.
                        These cookies:
                    </p>
                    <ul>
                        <li>Help the website remember your session, preferences, and login status.</li>
                        <li>Do not track your browsing behavior or serve advertisements.</li>
                    </ul>
                    <p>No third-party tracking or marketing cookies are used.</p>

                    <h2>3. Personal Data Collection</h2>
                    <p>We collect and store only the following information about you:</p>
                    <ul>
                        <li>Your Discord ID</li>
                        <li>Your Discord Username</li>
                        <li>A set of permissions</li>
                        <li>Game data you produce by playtesting</li>
                    </ul>
                    <p>
                        Your Discord ID and Username are collected when you authenticate via Discord to access some parts of the site.
                        All data is stored in the European Union, primarily in Germany. No personal information is transferred outside the EU.
                    </p>

                    <h2>4. How We Use Your Data</h2>
                    <p>We use your Discord ID and username for:</p>
                    <ul>
                        <li>Identifying you on the website.</li>
                        <li>Managing your account and providing access to website features.</li>
                    </ul>
                    <p>We use a set of permissions to:</p>
                    <ul>
                        <li>Authorize your use of certain parts of the website.</li>
                    </ul>
                    <p>We do not share your personal information with third parties, except for the purpose of authentication via Discord.</p>

                    <h2>5. User Rights</h2>
                    <p>You have the following rights regarding your data:</p>
                    <ul>
                        <li><strong>Download Your Data:</strong> You can request a copy of the data we store about you.</li>
                        <li><strong>Delete Your Account:</strong> You can delete your account at any time. Upon deletion, all stored data related to your account will be permanently removed.</li>
                    </ul>

                    <h2>6. Third-Party Authentication</h2>
                    <p>
                        We use Discord for third-party authentication. When you log in via Discord, we only collect your Discord ID and username.
                        Please review <a href='https://discord.com/privacy'>Discord’s privacy policy</a> to understand how they handle your personal information.
                    </p>

                    <h2>7. Security</h2>
                    <p>
                        Reasonable measures are taken to protect your data from unauthorized access or disclosure.
                    </p>

                    <h2>8. Changes to This Policy</h2>
                    <p>
                        We may update this policy from time to time. Any changes will be posted on this page with an updated revision date.
                        Your continued use of the website after any changes will constitute your acceptance of the updated policy.
                    </p>

                    <h2>9. Contact Us</h2>
                    <p>
                        If you have any questions or concerns about this policy or your personal data, please contact us at: wincent01 on Discord.
                    </p>
                </Container>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
