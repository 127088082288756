export function FormatEnumOption(value: string) {
    // Capitalize the first letter
    value = value.charAt(0).toUpperCase() + value.slice(1);
    
    // Replace - and _ with spaces and capitalize the next letter
    value = value.replace(/[-_]\w/g, match => ' ' + match.charAt(1).toUpperCase());

    // If there is a capital letter and no space before it, add a space
    value = value.replace(/([A-Z])/g, ' $1');
    
    const capitalize = [
        'ai',
        'id',
        'sql',
        'lego'
    ]

    // Capitalize all letters in the list
    for (let word of capitalize) {
        value = value.replace(new RegExp(word, 'gi'), word.toUpperCase());
    }
    
    return value;
}