import { useCallback, useState } from 'react';
import { NodeProps } from 'reactflow';
import { SimplePropsEntry } from '../SimplePropEntry';
import './behavior.css';

interface Props {
    value: any;
    setValue: (value: any) => void;
    name: string;
    title: string;
    inputType: string;
}

export const BehaviorPropWrapper: React.FC<Props> = ({ value, setValue, name, title, inputType }) => {
    const [ivalue, internalSetValue] = useState(value.values["parameters"][name] || 0);

    const setiValue = useCallback((val: any) => {
        internalSetValue(val);
        value.values["parameters"][name] = val;
        setValue(value);
    }, [value]);

    return (
        <div className='node-prop'>
            <SimplePropsEntry value={ivalue} setValue={setiValue} title={title} inputType={inputType} nullable={false} />
        </div>
    );
}
