import { useParams } from 'react-router-dom';
import { ModelViewer } from './ModelViewer';
import { modService } from '../ModService';
import { useState, useEffect } from 'react';

export const ModelEmbedded = () => {
    const  { "*": modelPath } = useParams();
    const [modelUrl, setModelUrl] = useState<string | null>(null);

    useEffect(() => {
        if (modelPath) {
            modService.getModelUrl(modelPath).then(setModelUrl);
        }
    }, [modelPath]);

    if (!modelPath || !modelUrl) {
        return null;
    }
    
    return (
        <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
            <ModelViewer modelUrl={modelUrl} />
        </div>
    );
}