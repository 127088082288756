import React, { Suspense } from "react";
import "./EquippedGear.css";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { modService } from "../../ModService";
import { ModelRenderer } from "../../Graphics/ModelRenderer";

interface EquippedGearProps {
    model: string,
    leftButtons: {url: string, onClick?: () => void}[]; // URLs for left image buttons
    rightButtons: {url: string, onClick?: () => void}[]; // URLs for right image buttons
}

const EquippedGear: React.FC<EquippedGearProps> = ({ model, leftButtons, rightButtons }) => {
    return (
        <div className="equipped-gear-container container-fluid">
            <h2 className="text-center text-white py-3">Equipped Gear</h2>
            <div className="row">
                {/* Left Column */}
                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                    {leftButtons.map((src, index) => (
                        <div key={index} className="gear-button mb-3"
                            onClick={src.onClick || (() => {})}>
                            <img className="gear-button-icon"
                                src={src.url}
                            />
                        </div>
                    ))}
                </div>

                {/* Center Column */}
                <div className="col-6">
                </div>

                {/* Right Column */}
                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                    {rightButtons.map((src, index) => (
                        <div key={index} className="gear-button mb-3"
                            onClick={src.onClick || (() => {})}>
                            <img className="gear-button-icon"
                                src={src.url}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EquippedGear;
