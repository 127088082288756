import { useEffect, useState } from 'react';
import { UnityPlayer } from './Unity/UnityPlayer';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { GenericEnum } from './GenericEnum';
import { GenericBtn } from './GenericBtn';
import { GenericInput } from './GenericInput';
import { JsxElement } from 'typescript';
import { ResourceExplorer } from './Resources/ResourceExplorer';
import { modService } from './ModService';
import Cookies from 'js-cookie';

const UnityPanel: React.FC = () => {
    const [showUnity, setShowUnity] = useState<boolean>(false);
    const [mode, setMode] = useState<string>("model");
    const [model, setModel] = useState<string>("");
    const [zone, setZone] = useState<string>("lego-universe:1000");
    const [relaod, setReload] = useState<boolean>(false);
    const [showResourceSelector, setShowResourceSelector] = useState<boolean>(false);
    const [sentZone, setSentZone] = useState<string>("");

    return (
        <div className='unity-container'>
            <Row>
                <Col md={2}>
                    <GenericBtn
                        title={showUnity ? "Hide Unity" : "Show Unity"}
                        onClick={() => setShowUnity(!showUnity)}
                    />
                </Col>
                <Col md={2}>
                    <GenericEnum
                        title="Mode"
                        value={mode}
                        setValue={setMode}
                        options={["model", "zone"]}
                    />
                </Col>
                {mode === "model" &&
                    <Col md={8}>
                        <GenericBtn
                            title={model || "Click to select model"}
                            onClick={() => setShowResourceSelector(true)}
                        />
                    </Col>
                }
                {mode === "zone" &&
                    <>
                        <Col md={6}>
                            <GenericInput
                                type='text'
                                value={zone}
                                setValue={(e) => setZone(e)}
                            />
                        </Col>
                        <Col md={2}>
                            <GenericBtn
                                title="Reload"
                                onClick={() => {
                                    if (sentZone === zone) {
                                        modService.reapplyProject(Cookies.get("project") || "default").then(() => {
                                            setReload(true);
                                        });
                                    }

                                    setSentZone(zone);
                                }}
                            />
                        </Col>
                    </>
                }
            </Row>
            <Row>
                {showUnity &&
                    <UnityPlayer
                        mode={mode || "zone"}
                        model={model}
                        zone={sentZone}
                        reload={relaod}
                        setReload={setReload}
                        project={Cookies.get("project") || "default"}
                    />
                }
            </Row>
            {showResourceSelector && (
                <ResourceExplorer
                    onSelect={(path, pack) => {
                        setModel(`${pack}:${path}`);
                        setShowResourceSelector(false);
                    }}
                    pack={model?.includes(':') ? model.split(':')[0] : 'lego-universe'}
                    // Do not include the pack or the filename
                    path={(model?.includes(':') ? model.split(':')[1] : model??"").split('/').slice(0, -1).join('/')}
                    onHidden={() => setShowResourceSelector(false)}
                />    
            )}
        </div>
    );
};

export { UnityPanel };