import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import './WelcomePage.css'; // Custom CSS for background image and animations
import CookieBanner from '../CookieBanner/CookieBanner';

interface WelcomePageProps {

}

const imageArray = [
    'image1',
    'image2',
    'image3',
    'image4',
    'image5'
]

export const WelcomePage: React.FC<WelcomePageProps> = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        // Trigger fade-in effect after component mounts
        setTimeout(() => setFadeIn(true), 100);
    }, []);

    useEffect(() => {
        // Change background image every 10 seconds
        const interval = setInterval(() => {
            setFadeIn(false); // Start fade-out effect
            setTimeout(() => {
                setImageIndex(prevIndex => (prevIndex + 1) % imageArray.length);
                setFadeIn(true); // Start fade-in effect
            }, 1000); // Wait for 0.5 seconds before switching the image
        }, 10000);


        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div>
                {/* Hero Section */}
                <div>
                    <div className={`hero-section ${fadeIn ? 'fade-in' : 'fade-out'}`}>
                        <div className={`hero-background ${imageArray[imageIndex]}`}>
                            <Container className="h-100 d-flex flex-column justify-content-center align-items-center text-center">
                                <div className="scroll-arrow">
                                    <ScrollLink
                                        to="content"
                                        smooth={true}
                                        duration={500}
                                        className="arrow"
                                    >
                                        ↓
                                    </ScrollLink>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <h1 className="fixed-header text-white">Nejlika, a Modding Platform</h1>
                </div>

                {/* Content Section */}
                <div id="content" className="content-section py-5">
                    <Container>
                        <Row>
                            <h3 className="text-center mb-4">Nejlika</h3>
                        </Row>
                        <Row>
                            <Col md={6} lg={4} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" src={`/images/create.png`} />
                                    <Card.Body>
                                        <Card.Title>Create</Card.Title>
                                        <Card.Text>
                                            Build upon the game with new worlds, objects, missions, and more.
                                            This can be done on your own or togther with a team.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        Go to the <a href="/editor">Editor</a> to get started.
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col md={6} lg={4} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" src={`/images/play.gif`} />
                                    <Card.Body>
                                        <Card.Title>Playtest</Card.Title>
                                        <Card.Text>
                                            Use the Nejlika Launcher to test your mods in-game and quickly iterate.
                                            Invite others to join you and playtest your new content.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        Download the <a href="/launcher">Launcher</a> to get started.
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col md={6} lg={4} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" src={`https://via.placeholder.com/300?text=${"Share"}`} />
                                    <Card.Body>
                                        <Card.Title>Share</Card.Title>
                                        <Card.Text>
                                            Your creations are yours. When you want to share them with a wider group,
                                            you can publish them on your own servers.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        This part is still in development.
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <h3 className="text-center mb-4">Credits</h3>
                        </Row>
                        <Row>
                            <Col md={6} lg={4} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" className='my-img mx-auto d-block' src={`/images/dlu.png`} />
                                    <Card.Body>
                                        <Card.Title>Darkflame Universe</Card.Title>
                                        <Card.Text>
                                            <a href="https://github.com/DarkflameUniverse/DarkflameServer" target="_blank" rel="noopener noreferrer">Darkflame Universe</a> is the server emulator that allows you to play LEGO® Universe.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <a href="https://github.com/DarkflameUniverse/DarkflameServer/#credits" target="_blank" rel="noopener noreferrer">Full Credits</a>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col md={6} lg={4} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" className='my-img mx-auto d-block' src={`/images/bm.webp`} />
                                    <Card.Body>
                                        <Card.Title>Brick Mesa</Card.Title>
                                        <Card.Text>
                                            Thanks to the modding community at Brick Mesa. Their work is instrumental in the LEGO® Universe modding scene.
                                        </Card.Text>
                                        <Card.Text>
                                            Their work is featured on this website and make Nejlika possible.
                                        </Card.Text>
                                        <Card.Text>
                                            Credits to:
                                        </Card.Text>
                                        <p>
                                            <a href="https://github.com/EmosewaMC" target="_blank" rel="noopener noreferrer">David Markowitz</a>,{' '}
                                            <a href="https://github.com/SimonNitzsche" target="_blank" rel="noopener noreferrer">Simon Nitzsche</a>,{' '}
                                            <a href="https://github.com/Jettford" target="_blank" rel="noopener noreferrer">Jettford</a>,{' '}
                                            <a href="https://github.com/cdmpants" target="_blank" rel="noopener noreferrer">Christopher Fantauzzo</a>,{' '}
                                            <a href="https://github.com/Terrev" target="_blank" rel="noopener noreferrer">Terrev</a>,{' '}
                                            <a href="https://github.com/Krysto2012" target="_blank" rel="noopener noreferrer">Krysto</a>,{' '}
                                            <a href="https://github.com/BrieFlory" target="_blank" rel="noopener noreferrer">Brie Flory</a>,{' '}
                                            and others
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6} lg={4} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" className='my-img mx-auto d-block' src={`/images/devil.jpg`} />
                                    <Card.Body>
                                        <Card.Title>NetDevil</Card.Title>
                                        <Card.Text>
                                            Thanks to the original developers at NetDevil and The LEGO Group for creating LEGO® Universe.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <h3 className="text-center mb-4">This Website</h3>
                        </Row>
                        <Row>
                            <Col md={6} lg={6} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" className='my-img cirular-img mx-auto d-block' src={`https://avatars.githubusercontent.com/u/18661153`} />
                                    <Card.Body>
                                        <Card.Title>Wincent</Card.Title>
                                        <Card.Text>
                                            Hi! I'm Wincent, the creator of this website and service. I was part of the original Darkflame Universe team that developed and released the server emulator.
                                        </Card.Text>
                                        <Card.Text>
                                            This is a pet-project of mine that aims to make modding LEGO® Universe more accessible.
                                        </Card.Text>
                                        <Card.Text>
                                            I have challenged myself to make Nejlika a fully fledged experience. It has encouraged to me learn React, Docker, Dev-Ops, and more.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <p>GitHub: <a href="https://github.com/Wincent01" target="_blank" rel="noopener noreferrer">Wincent01</a></p>
                                        <p>Email: <a href='mailto:wincent.holm@gmail.com'>wincent.holm@gmail.com</a></p>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col md={6} lg={6} className="mb-4">
                                <Card className="h-100">
                                    <Card.Img variant="top" className='my-img mx-auto d-block' src={`/images/api.svg`} />
                                    <Card.Body>
                                        <Card.Title>Development</Card.Title>
                                        <Card.Text>
                                            This website and service is still in development.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <p>The source code of the backend will be released on GitHub soon.</p>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Footer with links to policies */}
                <footer className="footer bg-dark text-white text-center py-3">
                    <Container>
                        <span>
                            <a href="/">Home</a>
                        </span>
                        <span> | </span>
                        <span>
                            <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy and Cookie Policy</a>
                        </span>
                    </Container>
                </footer>
            </div>
        </>
    );
}