import React, { useState } from 'react';
import './components.css';
import {
    Row,
    Col
} from 'react-bootstrap';
import { LocaleCard } from './LocaleCard';

interface SimplePropsEntryProps {
    value: any;
    setValue: (value: any) => void;
    title: string;
    inputType: string;
    nullable: boolean;
}

export const SimplePropsEntry: React.FC<SimplePropsEntryProps> = ({ value, setValue, title, inputType, nullable }) => {

    return (
        <Row className='w-100'>
            <Col md={4} className='left-text'>
                <span className='wheat-text'>{title}:</span>
            </Col>
            <Col md={nullable ? 7 : 8} className='left-text no-overflow'>
                {
                    /* Text input */
                    inputType === "text" &&
                    <input
                        className='wheat-text vs-bg locale-input w-100'
                        lang='en'
                        value={value || ""}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                }
                {
                    /* Textarea input */
                    inputType === "textarea" &&
                    <textarea
                        className='wheat-text vs-bg locale-input w-100'
                        lang='en'
                        value={value || ""}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                }
                {
                    /* Number input */
                    inputType === "number" &&
                    <input
                        className='wheat-text vs-bg locale-input w-100'
                        lang='en'
                        type='number'
                        value={value || 0}
                        onChange={(e) => {
                            let number = parseFloat(e.target.value);
                            if (isNaN(number)) {
                                number = 0;
                            }

                            setValue(number);
                        }}
                    />
                }
                {
                    /* Checkbox input */
                    inputType === "checkbox" &&
                    <input
                        type='checkbox'
                        checked={value || false}
                        onChange={(e) => {
                            setValue(e.target.checked);
                        }}
                    />
                }
            </Col>
            {nullable && <Col md={1} className='right-text'>
                <span className='danger' onClick={() => {
                    setValue(null);
                }}>(null)</span>
            </Col>}
        </Row>
    )
}