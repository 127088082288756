import { Template, ObjectProperty } from "./TypeTemplate";
import { modService } from "../ModService";

/**
 * A way to get and set properties of an object, based on a template.
 */
export class TypeTemplateWrapper {
    private template: Template;
    public getObject: () => any;
    private setObject: (object: any) => void;

    constructor(template: Template, getObject: () => any, setObject: (object: any) => void) {
        this.template = template;
        this.getObject = getObject;
        this.setObject = setObject;
    }
    
    static async fromObject(getObject: () => any, setObject: (object: any) => void): Promise<TypeTemplateWrapper> {
        const template = await modService.getModTemplate(getObject()["type"]);
        return new TypeTemplateWrapper(template, getObject, setObject);
    }

    getTemplate(): Template {
        return this.template;
    }

    getProperty(...path: (string | number)[]): [ObjectProperty, any?] {
        if (path.length === 0) {
            throw new Error("Path must have at least one element");
        }
        
        let value = this.getObject();
        let property: ObjectProperty = this.template.variables[path[0]];

        if (!property) {
            throw new Error(`Part ${path[0]} of ${path.join(".")} is invalid`);
        }

        if (!value[path[0]]) {
            return [property, undefined];
        }

        value = value[path[0]];

        for (let i = 1; i < path.length; i++) {
            if (property.type === "object") {
                if (property["object-properties"]) {
                    property = property["object-properties"][path[i]];

                    if (!property) {
                        throw new Error(`Part ${path[i]} of ${path.join(".")} is invalid`);
                    }

                    let nested = value[path[i]];

                    if (!nested && property.aliases) {
                        for (const key in property.aliases) {
                            if (value[key]) {
                                nested = value[key];
                                break;
                            }
                        }
                    }

                    if (!nested) {
                        return [property, undefined];
                    }

                    value = nested;
                }
                else if (property["array-type"]) {
                    property = property["array-type"];
                    value = value[path[i]];
                }
            }
            else if (property.type === "array") {
                if (property["array-type"]) {
                    property = property["array-type"];
                    value = value[path[i]];
                }
            }
            else {
                throw new Error(`Part ${path[i]} of ${path.join(".")} is invalid`);
            }
        }

        return [property, value];
    }

    async getNestedObject(...path: (string | number)[]): Promise<TypeTemplateWrapper | null | string> {
        const [property, value] = this.getProperty(...path);

        if (typeof value !== "object") {
            if (typeof value === "number") {
                return `lego-universe:${value}`;
            }

            if (typeof value === "string") {
                return value;
            }
            
            return null;
        }

        if (!value["type"]) {
            return null;
        }
        
        return await TypeTemplateWrapper.fromObject(() => value, (object: any) => {
            const newObject = this.getObject();
            let currentObject = newObject;
            for (let i = 0; i < path.length - 1; i++) {
                currentObject = currentObject[path[i]];
            }
            currentObject[path[path.length - 1]] = object;
            this.setObject(newObject);
        });
    }

    getObjectValue(...path: (string | number)[]): any {
        const [property, value] = this.getProperty(...path);
        return value;
    }
    
    setObjectValue(value: any, ...path: (string | number)[]): void {
        let object = this.getObject();
        let currentObject = object;
        for (let i = 0; i < path.length - 1; i++) {
            // Create empty objects/arrays if they don't exist
            if (!currentObject[path[i]]) {
                if (this.template.variables[path[i]].type === "object") {
                    currentObject[path[i]] = {};
                }
                else if (this.template.variables[path[i]].type === "array") {
                    currentObject[path[i]] = [];
                }
            }
            currentObject = currentObject[path[i]];
        }
        
        const last = path[path.length - 1];

        if (currentObject[last] === value) {
            return;
        }

        currentObject[last] = value;
        this.setObject(object);
    }

    addToArray(value: any, ...path: (string | number)[]): void {
        let object = this.getObject();
        let currentObject = object;
        for (let i = 0; i < path.length - 1; i++) {
            // Create empty objects/arrays if they don't exist
            if (!currentObject[path[i]]) {
                if (this.template.variables[path[i]].type === "object") {
                    currentObject[path[i]] = {};
                }
                else if (this.template.variables[path[i]].type === "array") {
                    currentObject[path[i]] = [];
                }
            }
            currentObject = currentObject[path[i]];
        }

        const last = path[path.length - 1];

        if (!currentObject[last]) {
            currentObject[last] = [];
        }

        currentObject[last].push(value);
        this.setObject(object);
    }

    getWithDefault(defaultValue: any, ...path: (string | number)[]): any {
        const value = this.getObjectValue(...path);
        return (value === undefined || value === null) ? defaultValue : value;
    }

    setDefault(value: any, ...path: (string | number)[]): boolean {
        const currentValue = this.getObjectValue(...path);
        if (currentValue === undefined || currentValue === null) {
            this.setObjectValue(value, ...path);
            return true;
        }

        return false;
    }

    removeFromArray(value: any, ...path: (string | number)[]): void {
        let object = this.getObject();
        let currentObject = object;
        for (let i = 0; i < path.length - 1; i++) {
            currentObject = currentObject[path[i]];
        }

        const last = path[path.length - 1];

        if (!currentObject[last]) {
            return;
        }

        currentObject[last] = currentObject[last].filter((v: any) => v !== value);
        this.setObject(object);
    }

    removeFromArrayByIndex(index: number, ...path: (string | number)[]): void {
        let object = this.getObject();
        let currentObject = object;
        for (let i = 0; i < path.length - 1; i++) {
            currentObject = currentObject[path[i]];
        }

        const last = path[path.length - 1];

        if (!currentObject[last]) {
            return;
        }

        currentObject[last].splice(index, 1);
        this.setObject(object);
    }
}