import { ModelRenderer } from "./ModelRenderer";
import "./Graphics.css";
import { WorldScene as WorldScene, World, SceneObject } from "../World";
import { modService } from "../ModService";

import React, { useEffect, useRef, useState, useCallback } from "react";
import {
    Engine,
    Scene,
    SceneLoader,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    AnimationGroup,
    Color4,
    Quaternion,
    TransformNode,
    BoundingInfo,
    ActionManager,
    ExecuteCodeAction,
    AbstractMesh,
    UniversalCamera,
    Mesh,
    DirectionalLight,
    Color3,
    Matrix,
    FreeCameraMouseInput,
    InstancedMesh,
    AssetContainer,
    MeshBuilder,
    Texture,
    ShaderMaterial
} from "@babylonjs/core";
import "@babylonjs/loaders"; // Ensures GLTF loader is included
import { Terrain, TerrainChunk } from "./Terrain";
import { TerrainLoader } from "./TerrainLoader";

interface TerrainViewerProps {
    terrain: string;
}

interface WorldModel {
    rootNode: TransformNode;
}

const loadedMeshes = new Map<string, AssetContainer>();
const loadingPromises = new Map<string, Promise<AssetContainer>>();

interface CullingEntry {
    cullDistanceSquared: number;
    abstractMeshes: AbstractMesh[];
}

const cullingEntries = new Array<CullingEntry>();
let previousCameraPosition: Vector3 = new Vector3(0, 0, 0);
let cameraMovementThreshold = 5;

export const TerrainViewer: React.FC<TerrainViewerProps> = ({ terrain }) => {
    const [terrainFile, setTerrainFile] = useState<Terrain | null>(null);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [engine, setEngine] = useState<Engine | null>(null);
    const [scene, setScene] = useState<Scene | null>(null);
    const [camera, setCamera] = useState<UniversalCamera | null>(null);
    const [models, setModels] = useState<WorldModel[]>([]);
    const [hemisphericLight, setHemisphericLight] = useState<HemisphericLight | null>(null);
    const [directionalLight, setDirectionalLight] = useState<DirectionalLight | null>(null);
    const [flipHorizontal, setFlipHorizontal] = useState(false);

    useEffect(() => {
        if (canvasRef.current) {
            const newEngine = new Engine(canvasRef.current, true);
            const newScene = new Scene(newEngine);
            setEngine(newEngine);
            setScene(newScene);

            // Setup camera and lights
            const camera = new UniversalCamera(
                "camera",
                new Vector3(0, 0, 0),
                newScene
            );
            camera.attachControl(canvasRef.current, true);

            newScene.useRightHandedSystem = true;

            // Move with WASD and arrow keys
            camera.keysUp = [87]; // W
            camera.keysDown = [83]; // S
            if (flipHorizontal) {
                camera.keysRight = [65]; // A
                camera.keysLeft = [68]; // D
            } else {
                camera.keysRight = [68]; // D
                camera.keysLeft = [65]; // A
            }
            camera.speed = 2;
            camera.inertia = 0.9;

            // Make the horizonal camera panning movement flip
            // Flip horizontal mouse movement
            if (flipHorizontal) {
                camera.angularSensibility *= -1;

                // Modify the projection matrix to flip horizontally
                const originalProjectionMatrix = camera.getProjectionMatrix();
                camera.getProjectionMatrix = function () {
                    if (flipHorizontal) {
                        let flipMatrix = originalProjectionMatrix;
                        // Flip horizontally
                        flipMatrix = flipMatrix.multiply(Matrix.Scaling(-1, 1, 1));
                        return flipMatrix;
                    }
                    return originalProjectionMatrix;
                };
            }

            setCamera(camera);
            
            const light = new HemisphericLight("light1", new Vector3(0, 1, 0), newScene);
            light.intensity = 1.5;

            setHemisphericLight(light);

            const directionalLight = new DirectionalLight("dir01", new Vector3(0, -1, 0), newScene);
            directionalLight.position = new Vector3(0, 50, 0);
            directionalLight.intensity = 1;

            setDirectionalLight(directionalLight);

            // Set background to transparent
            newScene.clearColor = new Color4(0, 0, 0, 0);

            // Render loop
            newEngine.runRenderLoop(() => {
                newScene.render();
            });

            const resize = () => {
                newEngine.resize();
            }

            // Resize the engine on window resize
            if (window) {
                window.addEventListener("resize", resize);
            }

            // Capture the scroll wheel event to prevent page scrolling
            const preventScroll = (event: WheelEvent) => {
                event.preventDefault();
            }

            if (canvasRef.current) {
                canvasRef.current.addEventListener("wheel", preventScroll);
            }

            // Cleanup
            return () => {
                newScene.dispose();
                newEngine.dispose();

                if (window) {
                    window.removeEventListener("resize", resize);
                }

                if (canvasRef.current) {
                    canvasRef.current.removeEventListener("wheel", preventScroll);
                }
            };
        }
    }, []);

    useEffect(() => {
        if (terrain) {
            modService.getModelUrl(terrain).then((terrainUrl) => {
                fetch(terrainUrl).then((response) => {
                    return response.json();
                }).then((terrainFile) => {
                    setTerrainFile(terrainFile);
                });
            });
        }
    }, [terrain]);

    return (
        <>
            {terrainFile && scene &&
            <>
                <TerrainLoader terrain={terrainFile} scene={scene} />
            </>
            }
            <div style={{ width: "100%", height: "100%" }}>
                <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
            </div>
        </>
    );
};